import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

export interface InfoMessageProps {
    title: string;
    message: string;
}

export const InfoMessage = (props: InfoMessageProps) => (
    <Alert severity="info">
        <AlertTitle>{props.title}</AlertTitle>
        {props.message}
    </Alert>
);
