import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { PlayerDto } from '../../model/player.dto';
import { PlayerIcon } from '../player-icon/player-icon.comp';

export interface PlayerSelectionProps {
    players: PlayerDto[];
    onSelectPlayer: (playerId: number) => void;
    suggestionIndex: number;
}

export const PlayerSelectionList = (props: PlayerSelectionProps) => {
    return (
        <Table>
            <TableBody>
                {props.players.map((player: PlayerDto, index: number) => (
                    <TableRow
                        hover={true}
                        style={{
                            cursor: 'pointer',
                            background: index === props.suggestionIndex ? 'lightgray' : 'white',
                        }}
                        key={player.id || 0}
                        onClick={() => props.onSelectPlayer(player.id)}
                    >
                        <TableCell style={{ width: 20, padding: 5 }}>
                            <div style={{ marginRight: 5, paddingLeft: 5 }}>
                                <PlayerIcon player={player} />
                            </div>
                        </TableCell>
                        <TableCell>{player.name}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
