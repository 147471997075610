import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { Fab } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export interface AsyncReloadButtonProps {
    loading: boolean;
    onClick: () => void;
}

const getCircularProgress = (props: AsyncReloadButtonProps) => {
    if (!props.loading) {
        return;
    }
    return (
        <CircularProgress
            size={68}
            style={{
                position: 'absolute',
                marginTop: -6,
                zIndex: 1,
                display: 'flex',
            }}
            data-testid="circularProgress"
        />
    );
};

export const AsyncReloadButton = (props: AsyncReloadButtonProps) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Fab
            aria-label="reload"
            color="secondary"
            onClick={props.onClick}
            disabled={props.loading}
        >
            <RefreshIcon />
        </Fab>
        {getCircularProgress(props)}
    </div>
);
