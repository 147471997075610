import { useCallback, useEffect, useState } from 'react';
import { useHttpClient } from '../clients/http-client/http-client';

interface SoundBoard {
    categories: Category[];
    playSound: (audio: HTMLAudioElement) => void;
    isSoundPlaying: boolean;
    stop: () => void;
}

interface Category {
    id: number;
    name: string;
    getRandomSound: () => HTMLAudioElement;
}

export const useSoundBoard = (): SoundBoard => {
    const [categories, setCategories] = useState<CategoryImpl[]>([]);
    const [isSoundPlaying, setIsSoundPlaying] = useState(false);
    const [currentlyPlayingAudios, setCurrentlyPlayingAudios] = useState(new Set<HTMLAudioElement>());

    useEffect(() => {
        setIsSoundPlaying(currentlyPlayingAudios.size > 0);
    }, [currentlyPlayingAudios]);

    const { get, getRaw } = useHttpClient();

    const addAudioToCategory = (categoryId: number, categoryName: string, audio: HTMLAudioElement) => {
        setCategories((oldCategories) => {
            const newCategories = [...oldCategories];
            const category = newCategories.find((category) => category.id === categoryId);
            if (category) {
                category.addAudio(audio);
            } else {
                const newCategory = new CategoryImpl(categoryId, categoryName);
                newCategory.addAudio(audio);
                newCategories.push(newCategory);
            }
            return newCategories;
        });
    };

    const addCategoryIfNotExists = (categoryId: number, categoryName: string) => {
        setCategories((oldCategories) => {
            const newCategories = [...oldCategories];
            const category = newCategories.find((category) => category.id === categoryId);
            if (category) {
                return newCategories;
            }
            const newCategory = new CategoryImpl(categoryId, categoryName);
            newCategories.push(newCategory);
            return newCategories;
        });
    };

    const playSound = (audio: HTMLAudioElement) => {
        setCurrentlyPlayingAudios((oldAudios) => {
            const newAudios = new Set(oldAudios);
            newAudios.add(audio);
            return newAudios;
        });
        audio.addEventListener('ended', () => {
            setCurrentlyPlayingAudios((oldAudios) => {
                const newAudios = new Set(oldAudios);
                newAudios.delete(audio);
                return newAudios;
            });
        });
        audio.play().then();
    };

    const stop = () => {
        currentlyPlayingAudios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        });
        setCurrentlyPlayingAudios(new Set());
    };

    const getAllCategories = useCallback(() => {
        get<CategoryResponse[]>('/sounds-api/categories').then((categoryOverviews) => {
            categoryOverviews.forEach((categoryOverview) => {
                addCategoryIfNotExists(categoryOverview.id, categoryOverview.name);
                get<CategoryDetailsResponse>(`/sounds-api/categories/${categoryOverview.id}`).then(
                    (categoryDetails) => {
                        categoryDetails.sounds.forEach((soundInfo) => {
                            getRaw(`/sounds-api/sounds/${soundInfo.id}`)
                                .then((response) => response.blob())
                                .then((blob) => URL.createObjectURL(blob))
                                .then((url) => new Audio(url))
                                .then((audio) => addAudioToCategory(categoryOverview.id, categoryOverview.name, audio));
                        });
                    }
                );
            });
        });
    }, [get, getRaw]);

    useEffect(() => {
        getAllCategories();
    }, [getAllCategories]);

    return {
        categories,
        playSound,
        isSoundPlaying,
        stop,
    };
};

class CategoryImpl implements Category {
    private readonly audios: HTMLAudioElement[] = [];

    constructor(
        readonly id: number,
        readonly name: string
    ) {}

    getRandomSound(): HTMLAudioElement {
        return this.audios[Math.floor(Math.random() * this.audios.length)];
    }

    addAudio(audio: HTMLAudioElement) {
        this.audios.push(audio);
    }
}

export class CategoryResponse {
    public id: number;
    public name: string;
}

export class CategoryDetailsResponse {
    public id: number;
    public name: string;
    public sounds: SoundResponse[];
}

export class SoundResponse {
    public id: number;
    public name: string;
}
