import { CircularProgress, IconButton } from '@mui/material';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import * as Base64 from 'base64-js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ErrorDialog } from '../error-dialog/error-dialog.comp';

export interface UploadIconButtonProps {
    existingImageUrl: string | undefined;
    onChangeFileContent: (fileContend: string) => void;
    fileUploadingChanged: (uploading: boolean) => void;
}

export const UploadIconButton = (props: UploadIconButtonProps) => {
    const [uploadingIcon, setUploadingIcon] = useState(false);
    const [fileContent, setFileContent] = useState(undefined);
    const [fileType, setFileType] = useState(undefined);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        props.fileUploadingChanged(uploadingIcon);
    }, [props, uploadingIcon]);

    useEffect(() => {
        props.onChangeFileContent(fileContent);
    }, [props, fileContent]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUploadingIcon(true);
        const file = event.target.files[0];
        if (!file.type.startsWith('image/')) {
            setError('Ej, den Bildtyp ' + file.type + ' kenne ich nicht! Probiere es bitte mit einem JPG oder PNG.');
            setUploadingIcon(false);
            return;
        }
        setFileType(file.type);
        const fileReader = new FileReader();
        fileReader.onload = () => {
            try {
                const newFileContent = Base64.fromByteArray(new Uint8Array(fileReader.result as ArrayBuffer));
                setFileContent(newFileContent);
            } catch (err) {
                setError(`Error: ${JSON.stringify(err)}`);
            }
            setUploadingIcon(false);
        };
        fileReader.readAsArrayBuffer(file);
    };

    const getIconButtonContent = () => {
        if (uploadingIcon) {
            return <CircularProgress style={{ width: 64, height: 64 }} />;
        }
        if (fileContent) {
            return (
                <img
                    style={{ maxWidth: 64, maxHeight: 64 }}
                    src={`data:${fileType};base64,${fileContent}`}
                    alt={'hochgeladenes Icon'}
                />
            );
        }
        if (props.existingImageUrl) {
            return (
                <img
                    style={{ maxWidth: 64, maxHeight: 64 }}
                    src={props.existingImageUrl}
                    alt={'existierendes Icon'}
                />
            );
        }
        return <TagFacesIcon style={{ width: 64, height: 64 }} />;
    };
    return (
        <>
            <IconButton
                style={{ width: 64, height: 64 }}
                component="label"
                size="large"
            >
                {getIconButtonContent()}
                <input
                    disabled={uploadingIcon}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                    data-testid="iconInput"
                />
            </IconButton>
            <ErrorDialog
                title={'Fehler'}
                message={error}
                show={!!error}
                onDismiss={() => setError(undefined)}
            />
        </>
    );
};
