import { useCallback, useEffect, useState } from 'react';
import { PlayerDto } from '../model/player.dto';
import { usePlayerClient } from '../clients/player-client/player-client';

export const usePlayers = () => {
    const [players, setPlayers] = useState<PlayerDto[]>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const { loadAllPlayers, loadPlayer } = usePlayerClient();

    const load = useCallback(() => {
        setLoading(true);
        setError(false);
        loadAllPlayers()
            .then((response) => setPlayers(response))
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [loadAllPlayers]);

    const addPlayer = (player: PlayerDto) => {
        setPlayers([...players, player]);
    };

    const addPlayers = useCallback((newPlayers: PlayerDto[]) => {
        setPlayers((currentPlayers) => [...currentPlayers, ...newPlayers]);
    }, []);

    const deletePlayer = (playerDto: PlayerDto): void => {
        const playersCopy = [...players];
        playersCopy.find((player) => player.id === playerDto.id).active = false;
        setPlayers([...playersCopy]);
    };

    const editPlayer = (playerDto: PlayerDto): void => {
        const original = players.find((p: PlayerDto) => p.id === playerDto.id);
        original.name = playerDto.name;
        original.imageUrl = playerDto.imageUrl;
        setPlayers([...players]);
    };

    const loadPlayers = useCallback(
        (playerIds: number[]) => {
            Promise.all(playerIds.map((id) => loadPlayer(id)))
                .then(addPlayers)
                .catch(() => setError(true));
        },
        [loadPlayer, addPlayers]
    );

    useEffect(load, [load]);

    return {
        players,
        isLoading,
        error,
        reload: load,
        addPlayer,
        deletePlayer,
        editPlayer,
        loadPlayers,
    };
};
