import { TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { useScreenSize } from '../../hooks/use-screen-size';
import { GameDto } from '../../model/game.dto';
import { PlayerDto } from '../../model/player.dto';
import { Card } from '../card.comp';
import { ErrorReloadMessage } from '../error-reload-message/error-reload-message.comp';
import { GamesTableRow } from './games-table-row.comp';

export interface GamesTableProps {
    players: PlayerDto[];
    games: GameDto[];
    loadingGames: boolean;
    reload: () => void;
    errorLoadingGames?: any;
    reloadCurrentPage: () => void;
    loadNextPage: () => void;
    deleteGame: (id: number) => void;
}

export const GamesTable = (props: GamesTableProps) => {
    useTriggerIfVisible(
        () => document.querySelector('.loading-game-row'),
        props.loadNextPage,
        props.games,
        props.errorLoadingGames
    );
    const { innerWidth } = useScreenSize();

    const showStandortCol = innerWidth > 650;
    const showIconCols = innerWidth > 400;
    const nameColMaxWidth = Math.min(0.1 * innerWidth, 150);

    return (
        <Card
            style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 24,
                maxWidth: 750,
            }}
        >
            {
                <>
                    {props.errorLoadingGames && !props.games ? (
                        <ErrorReloadMessage
                            message={'Die Spiele konnten nicht geladen werden.'}
                            loading={props.loadingGames}
                            details={props.errorLoadingGames}
                            reload={props.reload}
                        />
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datum</TableCell>
                                    {showStandortCol && <TableCell align="center">Standort</TableCell>}
                                    {showIconCols && <TableCell className="padding-4" />}
                                    <TableCell align="left">Team A</TableCell>
                                    <TableCell className="padding-4" />
                                    <TableCell className="padding-4" />
                                    <TableCell align="right">Team B</TableCell>
                                    {showIconCols && <TableCell className="padding-4" />}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gamesToRender(props.games, props.players, props.errorLoadingGames).map(
                                    (game: GameDto, index) => (
                                        <GamesTableRow
                                            key={index}
                                            game={game}
                                            players={props.players}
                                            showIconCols={showIconCols}
                                            showStandortCol={showStandortCol}
                                            nameColMaxWidth={nameColMaxWidth}
                                            onDeleteGame={(id: number) => props.deleteGame(id)}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    )}
                    {props.errorLoadingGames && props.games && (
                        <ErrorReloadMessage
                            message={'Die Spiele konnten nicht geladen werden.'}
                            loading={props.loadingGames}
                            details={props.errorLoadingGames}
                            reload={props.reloadCurrentPage}
                        />
                    )}
                </>
            }
        </Card>
    );
};

function gamesToRender(games: GameDto[], players: PlayerDto[], error: boolean) {
    if (!games || !players) {
        return twentyNotLoadedGames;
    }
    if (error) {
        return games;
    }
    return [...games, ...twentyNotLoadedGames];
}

const twentyNotLoadedGames = Array.from<GameDto>({ length: 20 }).map(() => undefined);

function callFunctionIfTargetBecomesVisible(fn: () => void, target: Element) {
    new IntersectionObserver((entries, observer) => {
        if (entries[0].isIntersecting) {
            observer.disconnect();
            fn();
        }
    }).observe(target);
}

function useTriggerIfVisible(findElement: () => Element, trigger: () => void, games: GameDto[], error: boolean) {
    const [shouldCreateNewObserver, setShouldCreateNewObserver] = useState(true);

    useEffect(() => {
        if (!games || !shouldCreateNewObserver || error) {
            return;
        }

        setShouldCreateNewObserver(false);
        callFunctionIfTargetBecomesVisible(() => {
            setShouldCreateNewObserver(true);
            trigger();
        }, findElement());
        // we do not include trigger and shouldCreateNewObserver in the array
        // because a change of these variables should not create a new intersection observer
        // eslint-disable-next-line
    }, [games, error]);
}
