import { useEffect, useState } from 'react';

export const LOCAL_STORAGE_RANKING_STANDORT_KEY = 'ranking-standort';
export const LOCAL_STORAGE_RANKING_SAISON_KEY = 'ranking-saison';
export const LOCAL_STORAGE_NEW_GAME_STANDORT_KEY = 'new-game-standort';

export function useLocalStorage(localStorageKey: string, defaultValue: string): [string, (newValue: string) => void] {
    const [value, setValue] = useState(() => {
        let currentValue;

        try {
            currentValue = JSON.parse(localStorage.getItem(localStorageKey) || String(defaultValue));
        } catch (error) {
            currentValue = defaultValue;
        }

        return currentValue;
    });

    useEffect(() => localStorage.setItem(localStorageKey, JSON.stringify(value)), [value, localStorageKey]);

    return [value, setValue];
}
