import Snackbar from '@mui/material/Snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';

import React, { Component } from 'react';
import { SnackbarContent } from '@mui/material';

export class SnackbarType {
    private constructor(
        public color: string,
        public icon: JSX.Element
    ) {}

    public static WARNING = new SnackbarType(
        'chocolate',
        (
            <WarningIcon
                fontSize="small"
                style={{ marginRight: 5 }}
            />
        )
    );
    public static SUCCESS = new SnackbarType(
        'green',
        (
            <CheckCircleIcon
                fontSize="small"
                style={{ marginRight: 5 }}
            />
        )
    );
    public static ERROR = new SnackbarType(
        'darkred',
        (
            <ErrorIcon
                fontSize="small"
                style={{ marginRight: 5 }}
            />
        )
    );
}

export interface SimpleSnackbarProps {
    show: boolean;
    message: string;
    onClose: () => void;
    type: SnackbarType;
}

export class SimpleSnackbar extends Component<SimpleSnackbarProps> {
    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={this.props.show}
                autoHideDuration={5000}
                onClose={this.props.onClose}
            >
                <SnackbarContent
                    style={{ backgroundColor: this.props.type.color }}
                    message={
                        <div style={{ display: 'flex' }}>
                            {this.props.type.icon}
                            {this.props.message}
                        </div>
                    }
                />
            </Snackbar>
        );
    }
}
