import { NewPlayerDto } from '../../model/new-player.dto';
import { PlayerStatisticsDto } from '../../model/player-statistics.dto';
import { PlayerDto } from '../../model/player.dto';
import { useHttpClient } from '../http-client/http-client';
import { useCallback } from 'react';

export const usePlayerClient = () => {
    const { get, post, patch, remove } = useHttpClient();

    const loadAllPlayers = useCallback(async (): Promise<PlayerDto[]> => {
        const players = await get<PlayerDto[]>('/api/player');
        return players.map((player) => PlayerDto.fromServerData(player));
    }, [get]);

    const loadAllPlayerStatistics = useCallback(async (): Promise<PlayerStatisticsDto[]> => {
        const response = await get<PlayerStatisticsDto[]>('/api/player/statistics');
        return response.map((dataElement) => PlayerStatisticsDto.fromServerData(dataElement));
    }, [get]);

    const loadPlayer = useCallback(
        async (id: number): Promise<PlayerDto> => {
            const response = await get<PlayerDto>(`/api/player/${id}`);
            return PlayerDto.fromServerData(response);
        },
        [get]
    );

    const addNewPlayer = useCallback(
        async (newPlayerDto: NewPlayerDto): Promise<PlayerDto> => {
            const response = await post<NewPlayerDto, PlayerDto>('/api/player', newPlayerDto);
            return PlayerDto.fromServerData(response);
        },
        [post]
    );

    const updatePlayer = useCallback(
        async (player: NewPlayerDto, id: number): Promise<PlayerDto> => {
            const response = await patch<NewPlayerDto, PlayerDto>(`/api/player/${id}`, player);
            return PlayerDto.fromServerData(response);
        },
        [patch]
    );

    const deletePlayer = useCallback(
        async (player: PlayerDto): Promise<void> => {
            await remove(`/api/player/${player.id}`);
        },
        [remove]
    );

    return {
        loadAllPlayers,
        loadAllPlayerStatistics,
        loadPlayer,
        addNewPlayer,
        deletePlayer,
        updatePlayer,
    };
};
