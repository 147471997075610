import { Skeleton, TableRow, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { PlayerStatisticsDto } from '../../model/player-statistics.dto';
import { TableColConfig } from '../player-table/player-table.comp';
import { PlayerIcon } from '../player-icon/player-icon.comp';

export const PlayerRowTestIds = {
    playerName: 'player-name',
    icon: 'icon',
};

export interface PlayerRowProps {
    playerStatistics: PlayerStatisticsDto;
    onDeletePlayer: () => void;
    onEditPlayer: () => void;
    tableColConfig: TableColConfig;
    loadStatisticsError: boolean;
}

export const PlayerRow = (props: PlayerRowProps) => (
    <TableRow>
        <TableCell
            className="padding-4"
            width="36px"
            style={{ paddingLeft: 16 }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PlayerIcon
                    player={props.playerStatistics}
                    data-testid={PlayerRowTestIds.icon}
                    size={36}
                />
            </div>
        </TableCell>
        <TableCell
            className="text-ellipsis"
            style={{ maxWidth: 120 }}
        >
            {!props.playerStatistics ? <Skeleton /> : props.playerStatistics.name}
        </TableCell>
        {props.tableColConfig.showStandortCol && (
            <TableCell>{!props.playerStatistics ? <Skeleton /> : props.playerStatistics.standort}</TableCell>
        )}
        {props.tableColConfig.showGamesCol && (
            <TableCell align="right">
                <ErrorSkeletonOrValue
                    hasError={props.loadStatisticsError}
                    isLoading={
                        props.playerStatistics?.winCount === undefined ||
                        props.playerStatistics?.lossesCount === undefined
                    }
                    value={props.playerStatistics?.winCount + props.playerStatistics?.lossesCount}
                />
            </TableCell>
        )}
        {props.tableColConfig.showWinsCol && (
            <TableCell align="right">
                <ErrorSkeletonOrValue
                    hasError={props.loadStatisticsError}
                    isLoading={props.playerStatistics?.winCount === undefined}
                    value={props.playerStatistics?.winCount}
                />
            </TableCell>
        )}
        {props.tableColConfig.showLossesCol && (
            <TableCell align="right">
                <ErrorSkeletonOrValue
                    hasError={props.loadStatisticsError}
                    isLoading={props.playerStatistics?.lossesCount === undefined}
                    value={props.playerStatistics?.lossesCount}
                />
            </TableCell>
        )}
        {props.tableColConfig.showGoalsCol && (
            <TableCell align="right">
                <ErrorSkeletonOrValue
                    hasError={props.loadStatisticsError}
                    isLoading={props.playerStatistics?.totalGoals === undefined}
                    value={props.playerStatistics?.totalGoals}
                />
            </TableCell>
        )}
        <TableCell width="88px">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {!props.playerStatistics ? (
                    <>
                        <Skeleton
                            variant="circular"
                            width="24px"
                            height="24px"
                        />
                        <Skeleton
                            variant="circular"
                            width="24px"
                            height="24px"
                        />
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={() => props.onEditPlayer()}
                            data-testid="edit_player_button"
                            size="large"
                        >
                            <EditIcon
                                fontSize="small"
                                color="primary"
                            />
                        </IconButton>
                        <IconButton
                            onClick={() => props.onDeletePlayer()}
                            size="large"
                        >
                            <DeleteIcon
                                fontSize="small"
                                color="secondary"
                            />
                        </IconButton>
                    </>
                )}
            </div>
        </TableCell>
    </TableRow>
);

const ErrorSkeletonOrValue = (props: { hasError: boolean; isLoading: boolean; value: string | number }) => {
    if (props.hasError) {
        return <Typography title="Fehler beim Laden der Daten">-</Typography>;
    }
    if (props.isLoading) {
        return <Skeleton />;
    }
    return <>{props.value}</>;
};
