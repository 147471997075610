import * as React from 'react';
import { useState } from 'react';
import { PlayerDto } from '../../model/player.dto';
import { AsyncConfirmDialog } from '../async-confirm-dialog/async-confirm-dialog.comp';
import { ErrorDialog } from '../error-dialog/error-dialog.comp';
import { SimpleSnackbar, SnackbarType } from '../simple-snackbar/simple-snackbar.comp';
import { usePlayerClient } from '../../clients/player-client/player-client';

export interface DeletePlayerDialogProps {
    playerToDelete: PlayerDto;
    onDeletePlayer: () => void;
    onDismiss: () => void;
}

export const DeletePlayerDialog = (props: DeletePlayerDialogProps) => {
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const { deletePlayer } = usePlayerClient();

    const onConfirm = () => {
        setLoading(true);
        deletePlayer(props.playerToDelete)
            .then(() => {
                props.onDeletePlayer();
                props.onDismiss();
                setShowSuccessSnackbar(true);
            })
            .catch((error) => {
                setErrorMessage(error.message);
                setShowErrorDialog(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <AsyncConfirmDialog
                title="Spieler löschen"
                loading={loading}
                message={`Möchten Sie den Spieler ${
                    props.playerToDelete && props.playerToDelete.name
                } unwiderruflich löschen?`}
                onConfirm={onConfirm}
                onDismiss={props.onDismiss}
                show={!!props.playerToDelete}
            />
            <ErrorDialog
                message={errorMessage}
                title={'Fehler beim Löschen'}
                onDismiss={() => setShowErrorDialog(false)}
                show={showErrorDialog}
            />
            <SimpleSnackbar
                show={showSuccessSnackbar}
                message={`Der Spieler wurde gelöscht`}
                onClose={() => setShowSuccessSnackbar(false)}
                type={SnackbarType.SUCCESS}
            />
        </>
    );
};
