import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import { useScreenSize } from '../../hooks/use-screen-size';
import { RankingTypeDto } from '../../model/ranking/ranking-type.dto';
import { AsyncReloadButton } from '../async-reload-button/async-reload-button.comp';
import { SelectSkeleton } from '../select-skeleton/select-skeleton.comp';
import { SelectStandort } from '../select-standort/select-standort.comp';
import { SelectSaison } from '../select-saison/select-saison.comp';

export interface RankingConfigProps {
    disabled: boolean;
    rankingTypes: RankingTypeDto[];
    currentRankingTypeIndex: number;
    setCurrentRankingTypeIndex: (index: number) => void;
    selectedStandort: string;
    setSelectedStandort: (standort: string) => void;
    selectedSaison: string;
    setSelectedSaison: (saison: string) => void;
    showLastGames: number;
    setShowLastGames: (lastGames: number) => void;
    showOnlyLastGames: boolean;
    setShowOnlyLastGames: (showOnlyLastGames: boolean) => void;
    reloadRanking: () => void;
}

const showLastGamesOptions = [50, 100, 200, 500];

export const RankingConfig = (props: RankingConfigProps) => {
    const { innerWidth } = useScreenSize();

    const [expanded, setExpanded] = useState(false);

    const collapseEnabled = innerWidth <= 3 * 24 + 2 * 200;

    return (
        <div
            style={{
                display: 'flex',
                padding: 24,
                justifyContent: 'flex-start',
                maxHeight: collapseEnabled && !expanded ? 48 : 406,
                transition: 'max-height 0.2s ease-in-out',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    gap: 24,
                }}
            >
                {!props.rankingTypes ? (
                    <SelectSkeleton width={200} />
                ) : (
                    <FormControl variant="standard">
                        <InputLabel id="ranking-select-label">Ranking</InputLabel>
                        <Select
                            style={{ width: 200 }}
                            value={props.currentRankingTypeIndex}
                            onChange={(event: SelectChangeEvent<number>) =>
                                props.setCurrentRankingTypeIndex(event.target.value as number)
                            }
                            disabled={props.disabled}
                            labelId="ranking-select-label"
                            label="Ranking"
                        >
                            {props.rankingTypes.map((ranking, index) => (
                                <MenuItem
                                    value={index}
                                    key={index}
                                >
                                    {ranking.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <SelectStandort
                    showAllStandorteOption={true}
                    onChange={props.setSelectedStandort}
                    selectedStandort={props.selectedStandort}
                    disabled={props.disabled}
                    style={{ width: 200 }}
                />

                <SelectSaison
                    onChange={props.setSelectedSaison}
                    selectedSaison={props.selectedSaison}
                    disabled={props.disabled}
                    style={{ width: 200 }}
                />

                <FormControl variant="standard">
                    <InputLabel id="select-games-label">Zeige Spiele</InputLabel>
                    <Select
                        style={{ width: 200 }}
                        value={props.showLastGames}
                        onChange={(event: SelectChangeEvent<number>) =>
                            props.setShowLastGames(event.target.value as number)
                        }
                        disabled={props.disabled}
                        labelId="select-games-label"
                        label="Zeige Spiele"
                    >
                        {showLastGamesOptions.map((showLastGames, index) => (
                            <MenuItem
                                value={showLastGames}
                                key={index}
                            >
                                {showLastGames}
                            </MenuItem>
                        ))}
                        <MenuItem
                            value={-1}
                            key={-1}
                        >
                            Alle
                        </MenuItem>
                    </Select>
                </FormControl>
                <div
                    style={{
                        width: 200,
                        display: 'flex',
                        alignItems: 'center',
                        color: '#757575',
                    }}
                >
                    <Typography variant="caption">Alle Spiele</Typography>
                    <Switch
                        value={props.showOnlyLastGames}
                        disabled={props.disabled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            props.setShowOnlyLastGames(event.target.checked as boolean)
                        }
                    />
                    <Typography variant="caption">Letzte Spiele</Typography>
                </div>
                <div
                    style={{
                        width: 200,
                        textAlign: 'center',
                    }}
                >
                    <AsyncReloadButton
                        loading={(!collapseEnabled || expanded) && props.disabled}
                        onClick={props.reloadRanking}
                    />
                </div>
            </div>
            {collapseEnabled && (
                <IconButton
                    style={{ height: 'max-content' }}
                    onClick={() => setExpanded(!expanded)}
                    size="large"
                >
                    <ExpandMore
                        style={{
                            transform: `rotate(${expanded ? 180 : 0}deg)`,
                            transition: 'transform 0.2s ease-in-out',
                        }}
                    />
                </IconButton>
            )}
        </div>
    );
};
