import { useCallback, useEffect, useState } from 'react';
import { PlayerDto } from '../model/player.dto';
import { Ranking } from '../model/ranking/ranking';
import { RankingTypeDto } from '../model/ranking/ranking-type.dto';
import { useRankingClient } from '../clients/ranking-client/ranking-client';

export const useRanking = (
    rankingType: RankingTypeDto,
    standorte: string,
    selectedSaison: string,
    showLastGames: number,
    onlyLastGames: boolean,
    players: PlayerDto[],
    loadPlayers: (playerIds: number[]) => void
) => {
    const [ranking, setRanking] = useState<Ranking>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const { loadRanking } = useRankingClient();

    const load = useCallback(() => {
        if (!rankingType || !players) {
            return;
        }
        setLoading(true);
        loadRanking(rankingType.url, onlyLastGames, standorte, selectedSaison, showLastGames)
            .then((ranking) => {
                ranking.gameRankings.forEach((gameRanking) => {
                    gameRanking.playerRankings.sort((a, b) => b.rankingValue - a.rankingValue);
                });
                const missingPlayerIds = ranking.getNewPlayerIds(players.map((player) => player.id));
                if (missingPlayerIds.length > 0) {
                    loadPlayers(missingPlayerIds);
                    return;
                }
                setRanking(ranking);
                setError(undefined);
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }, [rankingType, standorte, selectedSaison, showLastGames, onlyLastGames, players, loadRanking, loadPlayers]);

    // loadPlayersAndGames is not included, because they should not trigger a reload
    // eslint-disable-next-line
    useEffect(load, [load]);

    return { ranking, isLoading, error, reload: load };
};
