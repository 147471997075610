import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface ErrorDialogProps {
    title: string;
    message: string;
    show: boolean;
    onDismiss: () => void;
}

export const ErrorDialog = (props: ErrorDialogProps) => (
    <Dialog open={props.show}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => props.onDismiss()}
                color="primary"
                autoFocus
                variant="contained"
            >
                Ok
            </Button>
        </DialogActions>
    </Dialog>
);
