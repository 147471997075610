import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import React, { useState } from 'react';
import { usePlayers } from '../../hooks/use-players';
import { useScreenSize } from '../../hooks/use-screen-size';
import { BackendDownCard } from '../backend-down-card/backend-down.comp';
import { Games } from '../games/games.comp';
import { PlayerTable } from '../player-table/player-table.comp';
import { RankingTab } from '../ranking/ranking-tab.comp';
import { ActionButtons } from './action-button.comp';
import './app.css';
import { FooterTabs } from './footer-tabs.comp';
import { HeaderBar } from './header-bar.comp';
import { useGames } from '../../hooks/use-games';
import { SoundBoard } from '../sound/SoundBoard';

interface Tab {
    name: string;
    icon: JSX.Element;
    content: JSX.Element;
}

export const App = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { isMobile } = useScreenSize();

    const {
        players,
        error: playerLoadingError,
        addPlayer,
        deletePlayer,
        editPlayer,
        reload: reloadPlayers,
        loadPlayers,
    } = usePlayers();

    const games = useGames();

    const tabs: Tab[] = [
        {
            name: 'Ranking',
            icon: <ShowChartIcon />,
            content: (
                <RankingTab
                    changedGames={games.changedGames}
                    players={players}
                    loadPlayers={loadPlayers}
                />
            ),
        },
        {
            name: 'Spiele',
            icon: <ListAltIcon />,
            content: (
                <Games
                    players={players}
                    games={games}
                />
            ),
        },
        {
            name: 'Spieler',
            icon: <GroupIcon />,
            content: (
                <PlayerTable
                    onDeletePlayer={deletePlayer}
                    onEditPlayer={editPlayer}
                    players={players}
                />
            ),
        },
        {
            name: 'Sounds',
            icon: <MusicNoteIcon />,
            content: <SoundBoard />,
        },
    ];

    if (playerLoadingError) {
        return <BackendDownCard onClick={reloadPlayers} />;
    }
    return (
        <>
            <HeaderBar
                showTabs={!isMobile}
                tabs={tabs}
                currentTab={currentTab}
                onTabChange={setCurrentTab}
            />

            {tabs.map((tab, index) => (
                <div
                    key={index}
                    hidden={currentTab !== index}
                >
                    {tab.content}
                </div>
            ))}

            {isMobile && (
                <FooterTabs
                    tabs={tabs}
                    currentTab={currentTab}
                    onTabChange={setCurrentTab}
                />
            )}

            <ActionButtons
                isMobile={isMobile}
                showAddPlayerButton={currentTab === 2 && !!players}
                showNewGameButton={!!players}
                onAddPlayer={addPlayer}
                onGameAdded={games.addLocalGame}
                players={players}
            />

            <div className={currentTab === 2 ? 'foot-placeholder-two-buttons' : 'foot-placeholder-one-button'}></div>
        </>
    );
};
