import React, { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

export interface LoginWrapperProps {
    children: React.ReactNode;
}

export const LoginWrapper = (props: LoginWrapperProps) => {
    const { token, loginInProgress, login, error } = useContext<IAuthContext>(AuthContext);

    if (!loginInProgress && !token) {
        return (
            <>
                <CenteredBox>
                    <Typography>Du bist nicht eingeloggt.</Typography>
                    <Button
                        startIcon={<LoginIcon />}
                        onClick={() => login()}
                    >
                        Login
                    </Button>
                    {error && <Typography>Fehler: {error}</Typography>}
                </CenteredBox>
            </>
        );
    }

    if (token) {
        return <>{props.children}</>;
    }
    return (
        <>
            <CenteredBox>
                <CircularProgress />
                <Typography>Wir loggen dich ein, warte kurz...</Typography>
            </CenteredBox>
        </>
    );
};

const CenteredBox = (props: { children: React.ReactNode }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            position: 'absolute',
        }}
    >
        {props.children}
    </Box>
);
