import { PlayerDto } from '../player.dto';
import { GameRanking } from './game-ranking';

const findUnknownIds: (newIds: Set<number>, knownIds: number[]) => number[] = (newIds, loadedIds) => {
    const result: number[] = [];
    newIds.forEach((id) => {
        if (!loadedIds.includes(id)) {
            result.push(id);
        }
    });
    return result;
};

export class Ranking {
    constructor(
        public digis: number,
        public gameRankings: GameRanking[]
    ) {}

    getAllGameIds(): Set<number> {
        const result = new Set<number>();
        this.gameRankings.forEach((gameRanking) => result.add(gameRanking.game.id));
        return result;
    }

    getAllPlayerIds(): Set<number> {
        const result = new Set<number>();
        this.gameRankings.forEach((gameRanking) =>
            gameRanking.playerRankings.forEach((playerRanking) => result.add(playerRanking.playerId))
        );
        return result;
    }

    getAllPlayers(players: PlayerDto[]) {
        return players
            .filter((player) => player.active)
            .filter((player) => this.getAllPlayerIds().has(player.id))
            .sort(
                (a, b) =>
                    this.getLastGameRanking().playerRankings.find((r) => r.playerId === b.id).rankingValue -
                    this.getLastGameRanking().playerRankings.find((r) => r.playerId === a.id).rankingValue
            );
    }

    getLastGameRanking() {
        return this.gameRankings[this.gameRankings.length - 1];
    }

    getNewPlayerIds(knownPlayerIds: number[]): number[] {
        return findUnknownIds(this.getAllPlayerIds(), knownPlayerIds);
    }

    getNewGameIds(knownGameIds: number[]): number[] {
        return findUnknownIds(this.getAllGameIds(), knownGameIds);
    }

    getMinValue(shownPlayers?: PlayerDto[]): number {
        return Math.min(
            ...this.gameRankings.map((gr) =>
                Math.min(
                    ...gr.playerRankings
                        .filter((pr) => !shownPlayers || shownPlayers.find((p) => p.id === pr.playerId))
                        .map((pr) => pr.rankingValue)
                )
            )
        );
    }

    getMaxValue(shownPlayers?: PlayerDto[]): number {
        return Math.max(
            ...this.gameRankings.map((gr) =>
                Math.max(
                    ...gr.playerRankings
                        .filter((pr) => !shownPlayers || shownPlayers.find((p) => p.id === pr.playerId))
                        .map((pr) => pr.rankingValue)
                )
            )
        );
    }
}
