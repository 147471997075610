import { DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PlayerDto } from '../../model/player.dto';
import { AsyncButton } from '../async-button/async-button.comp';
import { ErrorDialog } from '../error-dialog/error-dialog.comp';
import { SelectStandort } from '../select-standort/select-standort.comp';
import { SimpleSnackbar, SnackbarType } from '../simple-snackbar/simple-snackbar.comp';
import { UploadIconButton } from '../upload-icon-button/upload-icon-button.comp';

export type SubmitPlayerResult = 'success' | 'name-already-exists' | 'unknown-error';

export interface SubmitPlayerDialogProps {
    showDialog: boolean;
    onCloseDialog: () => void;
    submitNewPlayer: (name: string, standort: string, icon: string) => Promise<SubmitPlayerResult>;
    editPlayer: (playerId: number, name: string, standort: string, icon: string) => Promise<SubmitPlayerResult>;
    playerToEdit?: PlayerDto;
}

export const SubmitPlayerDialog = (props: SubmitPlayerDialogProps) => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [iconFileContent, setIconFileContent] = useState(undefined);
    const [standort, setStandort] = useState(props.playerToEdit ? props.playerToEdit.standort : '');
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    const [showNameInUseError, setShowNameInUseError] = useState(false);
    const [showOtherError, setShowOtherError] = useState('');
    const [iconUploading, setIconUploading] = useState(false);

    useEffect(() => {
        if (!props.playerToEdit) {
            return;
        }
        setName(props.playerToEdit.name);
        setStandort(props.playerToEdit.standort);
        setIconFileContent(props.playerToEdit.imageUrl);
    }, [props.playerToEdit]);

    const resetInputs = () => {
        setName(props.playerToEdit ? props.playerToEdit.name : '');
        setStandort(props.playerToEdit ? props.playerToEdit.standort : '');
        setIconFileContent(undefined);
    };

    const resetErrors = () => {
        setShowNameInUseError(false);
        setShowOtherError('');
    };

    const getRequestPromise = () => {
        if (props.playerToEdit) {
            return props.editPlayer(props.playerToEdit.id, name, standort, iconFileContent);
        }
        return props.submitNewPlayer(name, standort, iconFileContent);
    };

    const submit = () => {
        if (!name || !standort) {
            return;
        }
        setLoading(true);
        resetErrors();
        getRequestPromise()
            .then((result) => {
                if (result === 'success') {
                    resetInputs();
                    setShowSuccessSnackbar(true);
                    props.onCloseDialog();
                }
                if (result === 'name-already-exists') {
                    setShowNameInUseError(true);
                }
                if (result === 'unknown-error') {
                    setShowOtherError('Unbekannter Fehler');
                }
            })
            .finally(() => setLoading(false));
    };

    const onKeyPress = (event: React.KeyboardEvent) => {
        if (event.key !== 'Enter') {
            return;
        }
        submit();
    };

    const getDialogTitle = () => {
        if (props.playerToEdit) {
            return 'Spieler bearbeiten';
        }
        return 'Neuen Spieler anlegen';
    };

    const onCloseDialog = () => {
        props.onCloseDialog();
        resetErrors();
        resetInputs();
    };
    return (
        <>
            <Dialog
                open={props.showDialog}
                onClose={onCloseDialog}
            >
                <DialogTitle>{getDialogTitle()}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        variant="standard"
                        label="Name"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                        autoFocus={true}
                        value={name}
                        error={showNameInUseError}
                        helperText={showNameInUseError ? 'Name nicht mehr verfügbar' : ''}
                        onKeyPress={onKeyPress}
                        style={{ marginBottom: 10, marginTop: 10, width: '100%' }}
                    />
                    <SelectStandort
                        onChange={setStandort}
                        selectedStandort={standort}
                        showAllStandorteOption={false}
                        disabled={false}
                        style={{ width: '100%' }}
                    />
                    <div style={{ marginTop: 10 }}>
                        <UploadIconButton
                            existingImageUrl={props.playerToEdit && props.playerToEdit.realImageUrl}
                            onChangeFileContent={setIconFileContent}
                            fileUploadingChanged={setIconUploading}
                        />
                    </div>
                </DialogContent>
                <DialogActions style={{ alignItems: 'end' }}>
                    <AsyncButton
                        loading={loading || iconUploading}
                        disabled={loading || !name || !standort || iconUploading}
                        onClick={submit}
                        text="Speichern"
                    />
                </DialogActions>
            </Dialog>

            <SimpleSnackbar
                show={showSuccessSnackbar}
                message={'Spieler gespeichert'}
                onClose={() => setShowSuccessSnackbar(false)}
                type={SnackbarType.SUCCESS}
            />

            <ErrorDialog
                title="Fehler"
                message={showOtherError}
                show={!!showOtherError}
                onDismiss={() => setShowOtherError('')}
            />
        </>
    );
};
