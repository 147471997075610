import { Fab } from '@mui/material';
import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export interface NewPlayerButtonProps {
    onClick: () => void;
}

export const NewPlayerButton = (props: NewPlayerButtonProps) => (
    <Fab
        variant="circular"
        color="primary"
        data-testid="add_player_button"
        onClick={props.onClick}
    >
        <PersonAddIcon />
    </Fab>
);
