import React from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { PlayerDto } from '../../model/player.dto';
import { Ranking } from '../../model/ranking/ranking';
import { getColor } from './material-colors';
import './ranking-chart.css';
import { RankingTooltip } from './ranking-tooltip.comp';
import { useData } from './use-data';

export interface RankingChartProps {
    ranking: Ranking;
    shownPlayers: PlayerDto[];
    width: number;
    height: number;
}

export const RankingChart = (props: RankingChartProps) => {
    const { data, hasLoaded } = useData(props.ranking, props.shownPlayers);

    return (
        <div
            role="figure"
            style={{
                display: 'table',
                width: props.width,
                height: props.height,
            }}
        >
            <LineChart
                data={data}
                width={props.width}
                height={props.height}
                margin={{
                    left: 30,
                    top: 30,
                    bottom: 10,
                    right: hasLoaded ? 0 : 30,
                }}
            >
                {hasLoaded && <CartesianGrid vertical={false} />}
                {hasLoaded && (
                    <XAxis
                        dataKey="name"
                        interval="preserveEnd"
                        minTickGap={20}
                    />
                )}
                {hasLoaded && (
                    <YAxis
                        type="number"
                        axisLine={false}
                        tickLine={false}
                        domain={[
                            Math.floor(props.ranking?.getMinValue(props.shownPlayers)),
                            Math.ceil(props.ranking?.getMaxValue(props.shownPlayers)),
                        ]}
                        orientation="right"
                    />
                )}
                {hasLoaded && (
                    <Tooltip
                        isAnimationActive={false}
                        // @ts-ignore
                        content={<RankingTooltip />}
                    />
                )}
                {!hasLoaded
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) => (
                          <Line
                              dot={false}
                              id={`skeletonLine${key}`}
                              className="ranking-chart-pulsing-line"
                              type="monotone"
                              key={key}
                              dataKey={key}
                              stroke={'lightgray'}
                              name={'abc'}
                              strokeWidth={2}
                          />
                      ))
                    : props.shownPlayers.map((player) => (
                          <Line
                              dot={false}
                              id={`lineOfPlayer${player.id}`}
                              type="monotone"
                              key={player.id}
                              dataKey={player.id}
                              stroke={getColor(player.id)}
                              name={player.name}
                              strokeWidth={2}
                          />
                      ))}
            </LineChart>
        </div>
    );
};
