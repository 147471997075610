import { PlayerDto } from '../../model/player.dto';
import { SubmitPlayerResult } from './submit-player-dialog.comp';
import { usePlayerClient } from '../../clients/player-client/player-client';
import { NewPlayerDto } from '../../model/new-player.dto';
import { NameAlreadyInUseError } from '../../clients/http-client/http-client.errors';

export function usePlayersBackend(onAddPlayer: (player: PlayerDto) => void) {
    const { addNewPlayer, updatePlayer } = usePlayerClient();
    const submitNewPlayer: (name: string, standort: string, icon: string) => Promise<SubmitPlayerResult> = (
        name,
        standort,
        icon
    ) => {
        return addNewPlayer(new NewPlayerDto(name, standort, icon))
            .then((player) => {
                onAddPlayer(player);
                return 'success';
            })
            .catch((err) => mapError(err)) as Promise<SubmitPlayerResult>;
    };

    const editPlayer: (id: number, name: string, standort: string, icon: string) => Promise<SubmitPlayerResult> = (
        id,
        name,
        standort,
        icon
    ) => {
        return updatePlayer(new NewPlayerDto(name, standort, icon), id)
            .then((player) => {
                onAddPlayer(player);
                return 'success';
            })
            .catch((err) => mapError(err)) as Promise<SubmitPlayerResult>;
    };
    return { submitNewPlayer, editPlayer };
}

function mapError(err: any): SubmitPlayerResult {
    if (err instanceof NameAlreadyInUseError) {
        return 'name-already-exists';
    }
    return 'unknown-error';
}
