import { PlayerDto } from './player.dto';

export class PlayerStatisticsDto extends PlayerDto {
    constructor(
        public id: number,
        public name: string,
        public imageUrl: string | null,
        public active: boolean,
        public winCount: number,
        public lossesCount: number,
        public totalGoals: number,
        public standort: string
    ) {
        super(id, name, imageUrl, active, standort);
    }

    public static fromServerData(playerStatisticsDto: PlayerStatisticsDto): PlayerStatisticsDto {
        return new PlayerStatisticsDto(
            playerStatisticsDto.id,
            playerStatisticsDto.name,
            playerStatisticsDto.imageUrl,
            playerStatisticsDto.active,
            playerStatisticsDto.winCount,
            playerStatisticsDto.lossesCount,
            playerStatisticsDto.totalGoals,
            playerStatisticsDto.standort
        );
    }
}
