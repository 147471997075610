import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './components/app/app';
import { ServiceWorkerUpdateHandler } from './ServiceWorkerUpdateHandler';
import { ServiceWorkerProvider } from './useServiceWorker';
import { LoginWrapper } from './login-wrapper';
import { AuthProvider, TAuthConfig } from 'react-oauth2-code-pkce';
import { PlayerIconContextProvider } from './player-icon-context';

const theme = createTheme();

const root = createRoot(document.getElementById('root'));
const authConfig: TAuthConfig = {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
    tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    scope: process.env.REACT_APP_AUTH_SCOPE,
};

root.render(
    <AuthProvider authConfig={authConfig}>
        <ServiceWorkerProvider>
            <ServiceWorkerUpdateHandler>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <ThemeProvider theme={theme}>
                        <LoginWrapper>
                            <PlayerIconContextProvider>
                                <App />
                            </PlayerIconContextProvider>
                        </LoginWrapper>
                    </ThemeProvider>
                </LocalizationProvider>
            </ServiceWorkerUpdateHandler>
        </ServiceWorkerProvider>
    </AuthProvider>
);
