import { TeamDto } from '../team.dto';

export class Team {
    constructor(
        public playerAid: number,
        public playerBid?: number
    ) {}
}

export function teamFromDto(dto: TeamDto) {
    return new Team(dto.idPlayerA, dto.idPlayerB);
}
