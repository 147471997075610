import { useServiceWorker } from './useServiceWorker';
import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const ServiceWorkerUpdateHandler = (props: { children: JSX.Element }) => {
    // @ts-ignore
    const { isUpdateAvailable, updateAssets } = useServiceWorker();

    useEffect(() => {
        if (isUpdateAvailable) {
            updateAssets();
        }
    }, [isUpdateAvailable, updateAssets]);

    return (
        <>
            <Dialog open={isUpdateAvailable}>
                <DialogTitle>Update verfügbar</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Es ist ein neues Update verfügbar. Die neuen Features wurden schon heruntergeladen, du musst die
                        Seite nur neu laden. Also, worauf wartest du noch?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => window.location.reload()}
                        color="primary"
                        variant="contained"
                    >
                        Seite neu Laden
                    </Button>
                </DialogActions>
            </Dialog>

            {props.children}
        </>
    );
};
