import { PlayerRankingDto } from '../../clients/ranking-client/ranking-client';

export class PlayerRanking {
    constructor(
        public playerId: number,
        public rankingValue: number
    ) {}
}

export function playerRankingFromDto(dto: PlayerRankingDto) {
    return new PlayerRanking(dto.playerId, dto.rankingValue);
}
