import { AppBar, Typography, Button, IconButton } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import LogoutIcon from '@mui/icons-material/Logout';

interface Tab {
    name: string;
    icon: JSX.Element;
}

export interface HeaderBarProps {
    showTabs: boolean;
    tabs: Tab[];
    currentTab: number;
    onTabChange: (newIndex: number) => void;
}

export const HeaderBar = (props: HeaderBarProps) => {
    const { logOut } = useContext<IAuthContext>(AuthContext);
    return (
        <>
            <AppBar
                style={{
                    position: 'fixed',
                    height: 56,
                    paddingLeft: 16,
                    paddingRight: 16,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        height: '100%',
                        maxWidth: 1450 + 24 + 400,
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 12,
                        justifyContent: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <img
                        src="foosball.svg"
                        width="24"
                        height="24"
                        alt="App Icon"
                    />
                    <Typography
                        variant="h6"
                        style={{
                            flexGrow: 1,
                        }}
                    >
                        Kicker-App
                    </Typography>
                    {props.showTabs &&
                        props.tabs.map((tab, index) => (
                            <Button
                                color="inherit"
                                variant="outlined"
                                key={index}
                                onClick={() => props.onTabChange(index)}
                                startIcon={tab.icon}
                                style={{
                                    borderColor: props.currentTab === index ? 'white' : 'transparent',
                                }}
                            >
                                {tab.name}
                            </Button>
                        ))}
                    <IconButton
                        onClick={() => logOut()}
                        color="inherit"
                    >
                        <LogoutIcon />
                    </IconButton>
                </div>
            </AppBar>
            <div style={{ height: 56 }}></div>
        </>
    );
};
