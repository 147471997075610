import { CSSProperties } from 'react';

export interface CardProps {
    children: JSX.Element;
    style?: CSSProperties;
}

export const Card = (props: CardProps) => (
    <div
        style={{
            boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.3)',
            borderRadius: 6,
            ...props.style,
        }}
    >
        {props.children}
    </div>
);
