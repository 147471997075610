export class PlayerDto {
    constructor(
        public id: number,
        public name: string,
        public imageUrl: string | null,
        public active: boolean,
        public standort: string
    ) {}

    public static fromServerData(playerDto: PlayerDto): PlayerDto {
        return new PlayerDto(playerDto.id, playerDto.name, playerDto.imageUrl, playerDto.active, playerDto.standort);
    }

    public get realImageUrl(): string {
        if (!this.imageUrl) {
            return null;
        }
        if (this.imageUrl.startsWith('http')) {
            return this.imageUrl;
        }
        return '/api/icon/' + this.imageUrl;
    }
}
