import { TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useCallback, useEffect, useState } from 'react';
import { usePlayerClient } from '../../clients/player-client/player-client';
import { useScreenSize } from '../../hooks/use-screen-size';
import { PlayerStatisticsDto } from '../../model/player-statistics.dto';
import { PlayerDto } from '../../model/player.dto';
import { Card } from '../card.comp';
import { DeletePlayerDialog } from '../delete-player-dialog/delete-player-dialog.comp';
import { InfoMessage } from '../info-message';
import { PlayerRow } from '../player-row/player-row.comp';
import { SubmitPlayerDialog } from '../submit-player-dialog/submit-player-dialog.comp';
import { usePlayersBackend } from '../submit-player-dialog/use-players-backend';

enum SortableColumn {
    NAME,
    STANDORT,
    GAMES,
    WINS,
    LOSSES,
    GOALS,
}

export interface PlayerTableProps {
    onDeletePlayer: (player: PlayerDto) => void;
    onEditPlayer: (player: PlayerDto) => void;
    players: PlayerDto[];
}

export interface TableColConfig {
    showLossesCol: boolean;
    showWinsCol: boolean;
    showStandortCol: boolean;
    showGamesCol: boolean;
    showGoalsCol: boolean;
}

export const PlayerTable = (props: PlayerTableProps) => {
    const [playerToDelete, setPlayerToDelete] = useState(undefined);
    const [playerToEdit, setPlayerToEdit] = useState(undefined);
    const { innerWidth } = useScreenSize();

    const { playerStatistics, orderDirection, orderBy, clickSortTable, loadStatisticsError } = usePlayerTable(
        props.players
    );

    const { submitNewPlayer, editPlayer } = usePlayersBackend(props.onEditPlayer);

    const tableColConfig: TableColConfig = {
        showGamesCol: innerWidth > 430,
        showStandortCol: innerWidth > 550,
        showWinsCol: innerWidth > 640,
        showGoalsCol: innerWidth > 740,
        showLossesCol: innerWidth > 860,
    };

    return (
        <Card style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 24, maxWidth: 900 }}>
            <>
                {playerStatistics?.length === 0 ? (
                    <InfoMessage
                        title="Keine Spieler vorhanden"
                        message="Füge mit dem Button unten rechts Spieler hinzu, damit es losgehen kann."
                    />
                ) : (
                    <Table>
                        <PlayerTableHead
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                            clickSortTable={clickSortTable}
                            tableColConfig={tableColConfig}
                        />
                        <TableBody>
                            {playerStatistics?.map((playerStatistics: PlayerStatisticsDto, index: number) => (
                                <PlayerRow
                                    key={index}
                                    playerStatistics={playerStatistics}
                                    loadStatisticsError={loadStatisticsError}
                                    tableColConfig={tableColConfig}
                                    onEditPlayer={() => setPlayerToEdit(playerStatistics)}
                                    onDeletePlayer={() => setPlayerToDelete(playerStatistics)}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
                <SubmitPlayerDialog
                    showDialog={!!playerToEdit}
                    onCloseDialog={() => setPlayerToEdit(undefined)}
                    submitNewPlayer={submitNewPlayer}
                    editPlayer={editPlayer}
                    playerToEdit={playerToEdit}
                />
                <DeletePlayerDialog
                    playerToDelete={playerToDelete}
                    onDeletePlayer={() => props.onDeletePlayer(playerToDelete)}
                    onDismiss={() => setPlayerToDelete(undefined)}
                />
            </>
        </Card>
    );
};

const PlayerTableHead = (props: {
    orderBy: SortableColumn;
    clickSortTable: (col: SortableColumn) => void;
    orderDirection: () => 'asc' | 'desc';
    tableColConfig: TableColConfig;
}) => (
    <TableHead>
        <TableRow>
            <TableCell />
            <TableCell>
                <TableSortLabel
                    active={props.orderBy === SortableColumn.NAME}
                    onClick={() => props.clickSortTable(SortableColumn.NAME)}
                    direction={props.orderDirection()}
                >
                    Name
                </TableSortLabel>
            </TableCell>
            {props.tableColConfig.showStandortCol && (
                <TableCell>
                    <TableSortLabel
                        active={props.orderBy === SortableColumn.STANDORT}
                        onClick={() => props.clickSortTable(SortableColumn.STANDORT)}
                        direction={props.orderDirection()}
                    >
                        Standort
                    </TableSortLabel>
                </TableCell>
            )}
            {props.tableColConfig.showGamesCol && (
                <TableCell align="right">
                    <TableSortLabel
                        active={props.orderBy === SortableColumn.GAMES}
                        onClick={() => props.clickSortTable(SortableColumn.GAMES)}
                        direction={props.orderDirection()}
                    >
                        Spiele
                    </TableSortLabel>
                </TableCell>
            )}
            {props.tableColConfig.showWinsCol && (
                <TableCell align="right">
                    <TableSortLabel
                        active={props.orderBy === SortableColumn.WINS}
                        onClick={() => props.clickSortTable(SortableColumn.WINS)}
                        direction={props.orderDirection()}
                    >
                        Siege
                    </TableSortLabel>
                </TableCell>
            )}
            {props.tableColConfig.showLossesCol && (
                <TableCell align="right">
                    <TableSortLabel
                        active={props.orderBy === SortableColumn.LOSSES}
                        onClick={() => props.clickSortTable(SortableColumn.LOSSES)}
                        direction={props.orderDirection()}
                    >
                        Niederlagen
                    </TableSortLabel>
                </TableCell>
            )}
            {props.tableColConfig.showGoalsCol && (
                <TableCell align="right">
                    <TableSortLabel
                        active={props.orderBy === SortableColumn.GOALS}
                        onClick={() => props.clickSortTable(SortableColumn.GOALS)}
                        direction={props.orderDirection()}
                    >
                        Tore
                    </TableSortLabel>
                </TableCell>
            )}
            <TableCell />
        </TableRow>
    </TableHead>
);

function usePlayerTable(players: PlayerDto[]) {
    const [playerStatistics, setPlayerStatistics] = useState(twentyNotLoadedPlayerStatistics);
    const [orderBy, setOrderBy] = useState(SortableColumn.GAMES);
    const [order, setOrder] = useState('desc');
    const [loadStatisticsError, setLoadStatisticsError] = useState(false);

    const { loadAllPlayerStatistics } = usePlayerClient();

    const loadStatistics = useCallback(() => {
        if (!players) {
            return;
        }
        loadAllPlayerStatistics()
            .then((playerStatistics: PlayerStatisticsDto[]) => {
                const statistics = playerStatistics.filter((player) => player.active);
                sortTableAndSetData(orderBy, order, statistics);
            })
            .catch(() => setLoadStatisticsError(true));
    }, [loadAllPlayerStatistics, order, orderBy, players]);

    useEffect(() => {
        if (players) {
            setPlayerStatistics(players.filter((player) => player.active));
        }
        loadStatistics();
        // we do not include order and orderBy, because statistics do not need to be reloaded when the order changes
        // eslint-disable-next-line
    }, [players, loadStatistics]);

    const clickSortTable = (column: SortableColumn) => {
        let order: 'asc' | 'desc';
        if (column === orderBy) {
            order = orderDirection();
        } else {
            order = 'desc';
        }
        sortTableAndSetData(column, order, playerStatistics);
    };

    const sortTableAndSetData = (column: SortableColumn, order: string, statistics: PlayerStatisticsDto[]) => {
        const statisticsCopy = [...statistics];
        statisticsCopy.sort((a, b) => {
            switch (column) {
                case SortableColumn.NAME:
                    return a.name.localeCompare(b.name);
                case SortableColumn.STANDORT:
                    return a.standort.localeCompare(b.standort);
                case SortableColumn.GAMES:
                    return b.winCount + b.lossesCount - (a.winCount + a.lossesCount);
                case SortableColumn.WINS:
                    return b.winCount - a.winCount;
                case SortableColumn.LOSSES:
                    return b.lossesCount - a.lossesCount;
                case SortableColumn.GOALS:
                    return b.totalGoals - a.totalGoals;
                default:
                    return a.name.localeCompare(b.name);
            }
        });
        if (order === 'asc') {
            statisticsCopy.reverse();
        }
        setPlayerStatistics(statisticsCopy);
        setOrderBy(column);
        setOrder(order);
    };

    const orderDirection = () => {
        return order === 'asc' ? 'desc' : 'asc';
    };

    return { playerStatistics, orderDirection, orderBy, clickSortTable, loadStatisticsError };
}

const twentyNotLoadedPlayerStatistics = Array.from<PlayerStatisticsDto>({ length: 20 }).map(() => undefined);
