import { useEffect, useState } from 'react';

const mobileMaxWidth = 700;

const callbacks: (() => void)[] = [];

function onResize() {
    callbacks.forEach((callback) => callback());
}

window.addEventListener('resize', onResize);

export const useScreenSize = () => {
    const [isMobile, setMobile] = useState(window.innerWidth <= mobileMaxWidth);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const setters = [
            () => setMobile(window.innerWidth <= mobileMaxWidth),
            () => setInnerHeight(window.innerHeight),
            () => setInnerWidth(window.innerWidth),
        ];
        callbacks.push(...setters);
        return () => {
            setters.forEach((setter) => {
                callbacks.splice(callbacks.indexOf(setter), 1);
            });
        };
    }, []);

    return { isMobile, innerHeight, innerWidth };
};
