import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';

export interface ClosableDialogTitleProps {
    title: string;
    onClose: () => void;
}

export const ClosableDialogTitle = (props: ClosableDialogTitleProps) => (
    <DialogTitle>
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {props.title}
            <IconButton
                color="inherit"
                onClick={props.onClose}
                size="large"
            >
                <CloseIcon />
            </IconButton>
        </div>
    </DialogTitle>
);
