import React, { useEffect, useState } from 'react';
import { useRanking } from '../../hooks/use-ranking';
import { useRankingTypes } from '../../hooks/use-ranking-types';
import { useScreenSize } from '../../hooks/use-screen-size';
import { GameDto } from '../../model/game.dto';
import { PlayerDto } from '../../model/player.dto';
import { Card } from '../card.comp';
import { ErrorReloadMessage } from '../error-reload-message/error-reload-message.comp';
import { InfoMessage } from '../info-message';
import { RankingChart } from '../ranking-chart/ranking-chart.comp';
import { RankingConfig } from '../ranking-config/ranking-config.comp';
import { RankingTable } from '../ranking-table/ranking-table.comp';
import { useShownPlayers } from './use-shown-players';
import {
    LOCAL_STORAGE_RANKING_SAISON_KEY,
    LOCAL_STORAGE_RANKING_STANDORT_KEY,
    useLocalStorage,
} from '../../hooks/use-local-storage';
import { ALL_TIME_SAISON } from '../select-saison/select-saison.comp';

export interface RankingTabProps {
    players: PlayerDto[];
    changedGames: GameDto[];
    loadPlayers: (playerIds: number[]) => void;
}

const leftColMaxWidth = 1450;
const rightColWidth = 400;
const margin = 24;

export const RankingTab = (props: RankingTabProps) => {
    const [currentRankingTypeIndex, setCurrentRankingTypeIndex] = useState(0);
    const [selectedStandort, setSelectedStandort] = useLocalStorage(LOCAL_STORAGE_RANKING_STANDORT_KEY, 'K');
    const [selectedSaison, setSelectedSaison] = useLocalStorage(LOCAL_STORAGE_RANKING_SAISON_KEY, ALL_TIME_SAISON);
    const [showLastGames, setShowLastGames] = useState(100);
    const [showOnlyLastGames, setShowOnlyLastGames] = useState(false);
    const [lastChangedGames, setLastChangedGames] = useState<GameDto[]>(undefined);

    const { innerWidth, innerHeight } = useScreenSize();

    const {
        isLoading: isLoadingRankingTypes,
        error: rankingTypesError,
        rankingTypes,
        reload: reloadRankingTypes,
    } = useRankingTypes();

    const selectedRankingType = rankingTypes && rankingTypes[currentRankingTypeIndex];

    const {
        ranking,
        error: rankingError,
        isLoading: isLoadingRanking,
        reload: reloadRanking,
    } = useRanking(
        selectedRankingType,
        selectedStandort,
        selectedSaison,
        showLastGames,
        showOnlyLastGames,
        props.players,
        props.loadPlayers
    );

    const { shownPlayers, showPlayer, hidePlayer, highlightPlayer } = useShownPlayers(ranking, props.players);

    useEffect(() => {
        if (lastChangedGames !== undefined && lastChangedGames.length < props.changedGames.length) {
            reloadRanking();
        }
        setLastChangedGames(props.changedGames);
        // eslint-disable-next-line
    }, [props.changedGames]);

    const backendError = rankingTypesError || rankingError;

    if (backendError) {
        return (
            <Card
                style={{
                    maxWidth: 1000,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <ErrorReloadMessage
                    message="Das Ranking konnte nicht geladen werden :("
                    details={backendError}
                    reload={rankingTypesError ? reloadRankingTypes : reloadRanking}
                    loading={isLoadingRankingTypes || isLoadingRanking}
                />
            </Card>
        );
    }

    const rankingNotEmpty = !ranking || ranking.gameRankings.length > 0;

    const noGamesMessage = (
        <InfoMessage
            title="Noch keine Spiele"
            message="Es gibt noch keine Spiele in diesem Ranking. Worauf wartest du noch?"
        />
    );

    return (
        <div
            style={{
                marginTop: margin,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: margin,
            }}
        >
            <div style={{ maxWidth: leftColMaxWidth }}>
                <Card style={{ marginBottom: margin }}>
                    <RankingConfig
                        disabled={isLoadingRankingTypes || isLoadingRanking}
                        rankingTypes={rankingTypes}
                        currentRankingTypeIndex={currentRankingTypeIndex}
                        setCurrentRankingTypeIndex={setCurrentRankingTypeIndex}
                        selectedStandort={selectedStandort}
                        setSelectedStandort={setSelectedStandort}
                        selectedSaison={selectedSaison}
                        setSelectedSaison={setSelectedSaison}
                        showLastGames={showLastGames}
                        setShowLastGames={setShowLastGames}
                        showOnlyLastGames={showOnlyLastGames}
                        setShowOnlyLastGames={setShowOnlyLastGames}
                        reloadRanking={reloadRanking}
                    />
                </Card>
                <Card>
                    {rankingNotEmpty ? (
                        <RankingChart
                            width={Math.min(innerWidth, leftColMaxWidth)}
                            height={Math.max(0.6 * innerHeight, 300)}
                            ranking={ranking}
                            shownPlayers={shownPlayers}
                        />
                    ) : (
                        noGamesMessage
                    )}
                </Card>
            </div>
            {rankingNotEmpty && (
                <Card style={{ width: rightColWidth }}>
                    <RankingTable
                        ranking={ranking}
                        players={props.players}
                        shownPlayers={shownPlayers}
                        showPlayer={showPlayer}
                        hidePlayer={hidePlayer}
                        highlightPlayer={highlightPlayer}
                    />
                </Card>
            )}
        </div>
    );
};
