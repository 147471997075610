import * as React from 'react';
import { Alert, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { AsyncButton } from '../async-button/async-button.comp';

export interface AsyncConfirmDialogProps {
    title: string;
    message: string;
    onConfirm: () => void;
    onDismiss: () => void;
    show: boolean;
    loading: boolean;
    errorMessage?: string;
    confirmText?: string;
}

export const AsyncConfirmDialog = (props: AsyncConfirmDialogProps) => (
    <Dialog
        open={props.show}
        onClose={props.onDismiss}
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{props.message}</DialogContentText>
            {props.errorMessage && (
                <Alert
                    severity="error"
                    sx={{ mt: 2 }}
                >
                    {props.errorMessage}
                </Alert>
            )}
        </DialogContent>
        <DialogActions>
            <Button
                onClick={props.onDismiss}
                color="primary"
            >
                Abbrechen
            </Button>
            <AsyncButton
                onClick={props.onConfirm}
                disabled={props.loading}
                loading={props.loading}
                text={props.confirmText || 'Bestätigen'}
            />
        </DialogActions>
    </Dialog>
);
