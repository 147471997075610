import { Typography } from '@mui/material';
import React from 'react';

export const RankingTooltip = (props: {
    active: boolean;
    payload: {
        dataKey: number;
        color: string;
        name: string;
        value: string;
    }[];
    label: string;
}) => {
    const { active, payload, label } = props;

    if (!active) {
        return null;
    }

    return (
        <div
            style={{
                backgroundColor: '#ffffffe6',
                padding: 2,
            }}
        >
            <Typography>{label}</Typography>
            {payload
                .sort((a, b) => Number(b.value) - Number(a.value))
                .map((entry) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            color: entry.color,
                        }}
                        key={entry.dataKey}
                    >
                        <div
                            style={{
                                marginRight: 10,
                            }}
                        >
                            <Typography>{entry.name}</Typography>
                        </div>
                        <div>
                            <Typography>{entry.value}</Typography>
                        </div>
                    </div>
                ))}
        </div>
    );
};
