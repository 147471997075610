import { useState, useEffect } from 'react';
import { PlayerDto } from '../../model/player.dto';
import { Ranking } from '../../model/ranking/ranking';

export const useShownPlayers = (ranking: Ranking, players: PlayerDto[]) => {
    const [shownPlayers, setShownPlayers] = useState<PlayerDto[]>([]);

    useEffect(() => {
        if (!ranking || ranking.gameRankings.length === 0 || !players) {
            return;
        }
        const currentGameRanking = ranking.gameRankings[ranking.gameRankings.length - 1];
        currentGameRanking.playerRankings.sort((a, b) => b.rankingValue - a.rankingValue);

        setShownPlayers(
            currentGameRanking.playerRankings
                .filter((_value, index) => index < 10)
                .map((ranking) => players.find((player) => player.id === ranking.playerId))
                .filter((player) =>
                    ranking.gameRankings[0].playerRankings.find((ranking) => ranking.playerId === player.id)
                )
        );
    }, [ranking, players]);

    const showPlayer = (player: PlayerDto) => {
        if (shownPlayers.includes(player)) {
            return;
        }
        setShownPlayers([...shownPlayers, player]);
    };

    const hidePlayer = (player: PlayerDto) => {
        if (!shownPlayers.includes(player)) {
            return;
        }
        const copy = [...shownPlayers];
        copy.splice(
            shownPlayers.findIndex((p) => p === player),
            1
        );
        setShownPlayers(copy);
    };

    const highlightPlayer = (player: PlayerDto) => {
        setShownPlayers([player]);
    };

    return { shownPlayers, showPlayer, hidePlayer, highlightPlayer };
};
