import { GameDto } from '../game.dto';
import { Team, teamFromDto } from '../team/team';

export class Game {
    constructor(
        public id: number,
        public teamA: Team,
        public teamB: Team,
        public goalsTeamA: number,
        public goalsTeamB: number,
        public lastGame: boolean,
        public date: string,
        public standort: string
    ) {}
}

export function gameFromDto(dto: GameDto) {
    return new Game(
        dto.id,
        teamFromDto(dto.teamA),
        teamFromDto(dto.teamB),
        dto.goalsTeamA,
        dto.goalsTeamB,
        dto.lastGame,
        dto.date,
        dto.standort
    );
}
