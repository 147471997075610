import React, { CSSProperties, useState } from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export interface SelectSaisonProps {
    onChange: (saison: string) => void;
    selectedSaison: string;
    disabled: boolean;
    style?: CSSProperties;
}

export const ALL_TIME_SAISON = 'ALL_TIME';

export const SelectSaison = (props: SelectSaisonProps) => {
    const [saisons] = useState(calculateSaisons());
    return (
        <FormControl
            style={{ width: 200, ...props.style }}
            variant="standard"
        >
            <InputLabel id="select-saison-label">Saison</InputLabel>
            <Select
                value={props.selectedSaison}
                disabled={props.disabled}
                data-testid="saison_select"
                onChange={(event: SelectChangeEvent) => {
                    props.onChange(event.target.value);
                }}
                labelId="select-saison-label"
                label="Saison"
            >
                {
                    <MenuItem
                        value={ALL_TIME_SAISON}
                        key={ALL_TIME_SAISON}
                    >
                        Alltime-Season
                    </MenuItem>
                }
                {saisons.map((saison) => (
                    <MenuItem
                        value={saison.value}
                        key={saison.value}
                    >
                        {saison.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const calculateSaisons = () => {
    const saisons: { label: string; value: string }[] = [];
    let year = new Date().getFullYear();
    let quartal = Math.floor(new Date().getMonth() / 3) + 1;
    while (true) {
        saisons.push({ label: `Q${quartal} ${year}`, value: `Q${quartal}-${year}` });
        if (quartal === 1) {
            saisons.push({ label: `${year}`, value: `${year}` });
        }
        if (year === 2019 && quartal === 3) {
            saisons.push({ label: `${year}`, value: `${year}` });
            break;
        }
        quartal--;
        if (quartal === 0) {
            quartal = 4;
            year--;
        }
    }
    return saisons;
};
