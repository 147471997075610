import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';
import { PlayerDto } from '../../model/player.dto';
import { Team } from '../../model/team/team';

export const GameSelectorTeam = (props: { team: Team; players: PlayerDto[]; bold: boolean }) => {
    if (!props.team || !props.players) {
        return (
            <>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </>
        );
    }
    const playerA = props.players.find((player) => player.id === props.team.playerAid);
    const playerB = props.players.find((player) => player.id === props.team.playerBid);
    if (playerB) {
        return (
            <>
                <PlayerRow
                    name={playerA.name}
                    bold={props.bold}
                />
                <PlayerRow
                    name={playerB.name}
                    bold={props.bold}
                />
            </>
        );
    }
    return (
        <PlayerRow
            name={playerA.name}
            bold={props.bold}
        />
    );
};

const PlayerRow = (props: { name: string; bold: boolean }) => (
    <Typography
        variant="body1"
        className={`game-selector-team-player text-ellipsis ${props.bold ? 'bold' : ''}`}
    >
        {props.name}
    </Typography>
);
