import { GameRankingDto } from '../../clients/ranking-client/ranking-client';
import { Game, gameFromDto } from '../game/game';
import { PlayerRanking, playerRankingFromDto } from './player-ranking';

export class GameRanking {
    constructor(
        public game: Game,
        public playerRankings: PlayerRanking[]
    ) {}
}

export function gameRankingFromDto(dto: GameRankingDto) {
    return new GameRanking(
        gameFromDto(dto.game),
        dto.playerRankings.map((playerRanking) => playerRankingFromDto(playerRanking))
    );
}
