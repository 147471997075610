import { Card } from '@mui/material';
import * as React from 'react';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';

export interface BackendDownCardProps {
    onClick: () => void;
}

export const BackendDownCard = (props: BackendDownCardProps) => (
    <div
        style={{
            display: 'table',
            position: 'absolute',
            width: '100%',
            height: '100%',
        }}
    >
        <div
            style={{
                display: 'table-cell',
                verticalAlign: 'middle',
            }}
        >
            <Card
                style={{
                    maxWidth: 300,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    boxShadow: 'none',
                    border: '1px lightgray solid',
                }}
            >
                <CardHeader
                    avatar={<ErrorIcon />}
                    title={'Netzwerk-Fehler'}
                    titleTypographyProps={{ variant: 'body1' }}
                ></CardHeader>
                <CardContent>
                    <Typography>
                        Das Backend ist leider nicht erreichbar, bitte versuchen Sie es später noch einmal.
                        <br />
                        In dringenden Notfällen erreichen Sie den 24/7 Support unter 0157/87084134.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={props.onClick}
                    >
                        Nochmals versuchen
                    </Button>
                </CardActions>
            </Card>
        </div>
    </div>
);
