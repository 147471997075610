import { TeamDto } from './team.dto';

export class NewGameDto {
    constructor(
        public teamA: TeamDto,
        public teamB: TeamDto,
        public goalsTeamA: number,
        public goalsTeamB: number,
        public isLastGame: boolean,
        public date: string,
        public standort: string
    ) {}
}
