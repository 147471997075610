import * as React from 'react';
import { useState } from 'react';
import { PlayerDto } from '../../model/player.dto';
import { SubmitPlayerDialog } from '../submit-player-dialog/submit-player-dialog.comp';
import { NewPlayerButton } from './new-player-button.comp';
import { usePlayersBackend } from '../submit-player-dialog/use-players-backend';

export interface NewPlayerProps {
    onAddPlayer: (player: PlayerDto) => void;
}

export const NewPlayer = (props: NewPlayerProps) => {
    const [showDialog, setShowDialog] = useState(false);
    const { submitNewPlayer, editPlayer } = usePlayersBackend(props.onAddPlayer);

    return (
        <>
            <NewPlayerButton onClick={() => setShowDialog(true)} />
            <SubmitPlayerDialog
                onCloseDialog={() => setShowDialog(false)}
                submitNewPlayer={submitNewPlayer}
                editPlayer={editPlayer}
                showDialog={showDialog}
            />
        </>
    );
};
