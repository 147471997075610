import { Skeleton } from '@mui/material';
import React from 'react';

export const SelectSkeleton = (props: { width: number }) => (
    <div style={{ width: props.width }}>
        <Skeleton
            variant="rectangular"
            width={props.width / 4}
            height={12}
        />
        <Skeleton
            variant="rectangular"
            width={props.width}
            height={32}
            style={{ marginTop: 4 }}
        />
    </div>
);
