import { PlayerDto } from '../../model/player.dto';
import React, { useContext, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { PlayerIconContext } from '../../player-icon-context';

const defaultSize = 24;

export const PlayerIcon = (props: { player?: PlayerDto; size?: number }) => {
    const size = {
        width: props.size || defaultSize,
        height: props.size || defaultSize,
    };

    const { loadImg, getImgSrc, isLoading } = useContext(PlayerIconContext);

    useEffect(() => {
        loadImg(props.player?.realImageUrl);
    }, [props.player?.realImageUrl, loadImg]);

    if (isLoading(props.player?.realImageUrl)) {
        return <IconSkeleton size={props.size} />;
    }

    const imgSrc = getImgSrc(props.player?.realImageUrl);
    if (!imgSrc) {
        return <div style={{ ...size }}></div>;
    }

    return (
        <img
            src={imgSrc}
            {...size}
            alt={props.player?.name}
        />
    );
};

export const IconSkeleton = (props: { size?: number }) => {
    const size = {
        width: props.size || defaultSize,
        height: props.size || defaultSize,
    };
    return (
        <Skeleton
            variant="circular"
            {...size}
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
        />
    );
};
