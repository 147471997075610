import moment from 'moment';
import { useEffect, useState } from 'react';
import { Game } from '../../model/game/game';
import { PlayerDto } from '../../model/player.dto';
import { GameRanking } from '../../model/ranking/game-ranking';
import { Ranking } from '../../model/ranking/ranking';

export const useData = (ranking: Ranking, shownPlayers: PlayerDto[]) => {
    const [data, setData] = useState(generateRandomData());

    useEffect(() => {
        if (!ranking) {
            return;
        }
        setData(ranking.gameRankings.map((gr) => createYValuesForGameRanking(gr, ranking.digis)));
    }, [ranking, shownPlayers]);

    return { data, hasLoaded: !!ranking };
};

const getXLabel = (game: Game) => `${moment(game.date).format('DD.MM.YY')}`;

const createYValuesForGameRanking = (gameRanking: GameRanking, digits: number) => {
    const result: any = {
        name: getXLabel(gameRanking.game),
    };
    gameRanking.playerRankings.forEach((playerRanking) => {
        result[playerRanking.playerId + ''] = playerRanking.rankingValue.toFixed(digits);
    });
    return result;
};

function generateRandomData() {
    const xMax = 50;
    const lines = 10;
    const result = [];
    for (let i = 0; i < xMax; i++) {
        const entry: any = {};
        for (let lineIndex = 0; lineIndex < lines; lineIndex++) {
            if (i === 0) {
                entry[`${lineIndex}`] = Math.random() * 10;
            } else {
                entry[`${lineIndex}`] = result[result.length - 1][`${lineIndex}`] + offset();
            }
        }
        result.push(entry);
    }
    return result;
}

function offset() {
    if (Math.random() < 0.8) {
        return 0;
    }
    return Math.random() - 0.5;
}
