import { Alert, AlertTitle } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AsyncButton } from '../async-button/async-button.comp';
import './error-reload-message.css';

export interface ErrorReloadMessageProps {
    message: string;
    details?: any;
    reload?: () => void;
    loading?: boolean;
}

export const ErrorReloadMessage = (props: ErrorReloadMessageProps) => {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <Alert
            severity="error"
            style={{
                maxWidth: '100%',
            }}
        >
            <AlertTitle>Fehler</AlertTitle>
            {props.message}
            {!!props.details && (
                <>
                    <div
                        onClick={() => setShowDetails(!showDetails)}
                        style={{
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Details
                        {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                    {showDetails && JSON.stringify(props.details, ['url', 'status', 'statusText', 'body'], 1)}
                </>
            )}
            {!!props.reload && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                    }}
                >
                    <AsyncButton
                        loading={props.loading}
                        disabled={props.loading}
                        onClick={props.reload}
                        text="Erneut versuchen"
                    />
                </div>
            )}
        </Alert>
    );
};
