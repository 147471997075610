import React, { CSSProperties } from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export interface SelectStandortProps {
    onChange: (standort: string) => void;
    selectedStandort: string;
    showAllStandorteOption: boolean;
    disabled: boolean;
    style?: CSSProperties;
}

export const SelectStandort = (props: SelectStandortProps) => {
    const standorte = [
        { name: 'Mannheim', kuerzel: 'MA' },
        { name: 'Karlsruhe', kuerzel: 'KA' },
        { name: 'Frankfurt', kuerzel: 'FFM' },
        { name: 'Stuttgart', kuerzel: 'ST' },
        { name: 'München', kuerzel: 'MU' },
        { name: 'Köln', kuerzel: 'K' },
    ];

    function renderAlleOptionIfNeeded() {
        if (!props.showAllStandorteOption) {
            return;
        }
        return (
            <MenuItem
                value="ALL"
                key="ALL"
            >
                Alle Standorte
            </MenuItem>
        );
    }

    return (
        <FormControl
            style={{ width: 200, ...props.style }}
            variant="standard"
        >
            <InputLabel id="select-standort-label">Standort</InputLabel>
            <Select
                value={props.selectedStandort}
                disabled={props.disabled}
                data-testid="standort_select"
                onChange={(event: SelectChangeEvent<string>) => {
                    props.onChange(event.target.value);
                }}
                labelId="select-standort-label"
                label="Standort"
            >
                {renderAlleOptionIfNeeded()}
                {standorte.map((standort) => (
                    <MenuItem
                        value={standort.kuerzel}
                        key={standort.kuerzel}
                    >
                        {standort.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
