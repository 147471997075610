import { GameDto } from '../../model/game.dto';
import { NewGameDto } from '../../model/new-game.dto';
import { useHttpClient } from '../http-client/http-client';
import { useCallback } from 'react';

export const useGameClient = () => {
    const { get, post, remove } = useHttpClient();

    const loadAllGames = useCallback((): Promise<GameDto[]> => {
        return get<GameDto[]>('/api/game');
    }, [get]);

    const loadGames = useCallback(
        (config: { page: number; size: number }): Promise<GameDto[]> => {
            return get<GameDto[]>(`/api/game?page=${config.page}&size=${config.size}`);
        },
        [get]
    );

    const addNewGame = useCallback(
        (newGameDto: NewGameDto): Promise<GameDto> => {
            return post<NewGameDto, GameDto>('/api/game', newGameDto);
        },
        [post]
    );

    const removeGame = useCallback(
        (id: number): Promise<void> => {
            return remove(`/api/game/${id}`);
        },
        [remove]
    );

    const loadGame = useCallback(
        (id: number): Promise<GameDto> => {
            return get<GameDto>(`/api/game/${id}`);
        },
        [get]
    );

    return { loadAllGames, loadGames, addNewGame, removeGame, loadGame };
};
