import React from 'react';
import { GameDto } from '../../model/game.dto';
import { PlayerDto } from '../../model/player.dto';
import { NewGame } from '../new-game/new-game.comp';
import { NewPlayer } from '../new-player/new-player.comp';

export interface ActionButtonProps {
    isMobile: boolean;
    showAddPlayerButton: boolean;
    showNewGameButton: boolean;
    onAddPlayer: (player: PlayerDto) => void;
    players: PlayerDto[];
    onGameAdded: (game: GameDto) => void;
}

export const ActionButtons = (props: ActionButtonProps) => (
    <div
        style={{
            position: 'fixed',
            bottom: 24 + (props.isMobile ? 56 : 0),
            right: 24,
            gap: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        }}
    >
        {props.showAddPlayerButton && <NewPlayer onAddPlayer={props.onAddPlayer} />}
        {props.showNewGameButton && (
            <NewGame
                players={props.players}
                onGameAdded={props.onGameAdded}
            />
        )}
    </div>
);
