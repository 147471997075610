import React, { useState } from 'react';
import * as serviceWorker from './serviceWorkerRegistration';

// @ts-ignore
const ServiceWorkerContext = React.createContext();

// @ts-ignore
export const ServiceWorkerProvider = ({ children }) => {
    const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false);

    React.useEffect(() => {
        serviceWorker.register({
            onUpdate: (registration) => {
                setWaitingServiceWorker(registration.waiting);
                setUpdateAvailable(true);
            },
        });
    }, []);

    const value = React.useMemo(
        () => ({
            isUpdateAvailable,
            updateAssets: () => {
                if (waitingServiceWorker) {
                    // We send the SKIP_WAITING message to tell the Service Worker
                    // to update its cache and flush the old one
                    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
                }
            },
        }),
        [isUpdateAvailable, waitingServiceWorker]
    );

    return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
    return React.useContext(ServiceWorkerContext);
};
