import React, { useState } from 'react';
import { GameDto } from '../../model/game.dto';
import { PlayerDto } from '../../model/player.dto';
import { GamesTable } from './games-table.comp';
import { AsyncConfirmDialog } from '../async-confirm-dialog/async-confirm-dialog.comp';
import moment from 'moment/moment';
import { AllGames } from '../../hooks/use-games';

export interface GamesProps {
    games: AllGames;
    players: PlayerDto[];
}

export const Games = (props: GamesProps) => {
    const [gameIdToDelete, setGameIdToDelete] = useState(undefined);
    const [deletingGame, setDeletingGame] = useState(false);
    const [errorDeletingGame, setErrorDeletingGame] = useState(false);

    return (
        <>
            <GamesTable
                players={props.players}
                games={props.games.allGames}
                reload={props.games.reload}
                reloadCurrentPage={props.games.reloadCurrentPage}
                loadingGames={props.games.loadingGames}
                errorLoadingGames={props.games.errorLoadingGames}
                loadNextPage={props.games.loadNextPage}
                deleteGame={(id) => setGameIdToDelete(id)}
            />
            <AsyncConfirmDialog
                title={'Spiel löschen'}
                message={createDeleteGameMessage(props.games.allGames?.find((game) => game.id === gameIdToDelete))}
                onConfirm={() => {
                    setDeletingGame(true);
                    props.games
                        .deleteGame(gameIdToDelete)
                        .then(() => {
                            setErrorDeletingGame(false);
                            setGameIdToDelete(undefined);
                        })
                        .catch(() => setErrorDeletingGame(true))
                        .finally(() => setDeletingGame(false));
                }}
                onDismiss={() => {
                    setGameIdToDelete(undefined);
                    setErrorDeletingGame(false);
                }}
                show={gameIdToDelete !== undefined || deletingGame}
                loading={deletingGame}
                errorMessage={errorDeletingGame ? 'Das Spiel konnte nicht gelöscht werden.' : undefined}
                confirmText={errorDeletingGame ? 'Nochmals versuchen' : 'Löschen'}
            />
        </>
    );
};

function createDeleteGameMessage(game?: GameDto) {
    if (!game) {
        return 'Möchtest du das Spiel wirklich löschen?';
    }
    return `Möchtest du das Spiel vom ${moment(game.date).format('DD.MM.YYYY, HH:mm:ss')} Uhr mit der ID ${game.id} wirklich löschen?`;
}
