import { useSoundBoard } from '../../hooks/use-sound-board';
import { Button } from '@mui/material';
import { useScreenSize } from '../../hooks/use-screen-size';

export const SoundBoard = () => {
    const { categories, playSound, isSoundPlaying, stop } = useSoundBoard();

    const { isMobile } = useScreenSize();

    return (
        <div
            style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                paddingLeft: 20,
                paddingRight: 20,
                marginTop: 24,
                maxWidth: 750,
                display: 'flex',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                gap: 20,
            }}
        >
            <>
                <Button
                    key="stop"
                    variant="contained"
                    onClick={() => stop()}
                    disabled={!isSoundPlaying}
                    color={'secondary'}
                    style={{
                        height: 80,
                        width: '100%',
                        fontSize: 24,
                    }}
                >
                    Stop
                </Button>
                {categories.map((category) => (
                    <Button
                        key={category.id}
                        variant="contained"
                        onClick={() => playSound(category.getRandomSound())}
                        style={{
                            height: 80,
                            width: 'calc(50% - 20px)',
                            fontSize: isMobile ? 18 : 24,
                        }}
                    >
                        {category.name}
                    </Button>
                ))}
            </>
        </div>
    );
};
