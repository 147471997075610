import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { useHttpClient } from './clients/http-client/http-client';

export interface PlayerIconContextModel {
    loadImg: (url: string) => void;
    getImgSrc: (url: string) => string;
    isLoading: (url: string) => boolean;
}

export const PlayerIconContext = createContext<PlayerIconContextModel>(null);

export function PlayerIconContextProvider(props: { children: ReactNode }) {
    const { getRaw } = useHttpClient();

    const [urlsToLoad, setUrlsToLoad] = useState<Set<string>>(new Set());
    const [playerIconObjectUrls, setPlayerIconObjectUrls] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        if (urlsToLoad.size === 0) {
            return;
        }

        const urlCreator = window.URL || window.webkitURL;

        Promise.all(
            Array.from(urlsToLoad).map((url) =>
                getRaw(url)
                    .then((response) => response.blob())
                    .then((blob) => urlCreator.createObjectURL(blob))
                    .then((text) => [url, text])
            )
        ).then((allImgData) => {
            setUrlsToLoad((old) => {
                const newUrls = new Set(old);
                allImgData.forEach(([url]) => newUrls.delete(url));
                return newUrls;
            });
            setPlayerIconObjectUrls((icons) => {
                const newIcons = new Map(icons);
                allImgData.forEach(([url, imgData]) => newIcons.set(url, imgData));
                return newIcons;
            });
        });
    }, [urlsToLoad, getRaw]);

    const loadImg = useCallback(
        (url: string): void => {
            if (!url || urlsToLoad.has(url) || playerIconObjectUrls.has(url)) {
                return;
            }

            setUrlsToLoad((old) => {
                const newUrls = new Set(old);
                newUrls.add(url);
                return newUrls;
            });
        },
        [playerIconObjectUrls, urlsToLoad]
    );

    const getImgSrc = useCallback((url: string) => playerIconObjectUrls.get(url), [playerIconObjectUrls]);

    const isLoading = useCallback((url: string) => urlsToLoad.has(url), [urlsToLoad]);

    const context: PlayerIconContextModel = {
        loadImg,
        getImgSrc,
        isLoading,
    };
    return <PlayerIconContext.Provider value={context}>{props.children}</PlayerIconContext.Provider>;
}
