import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { useScreenSize } from '../../hooks/use-screen-size';
import { PlayerDto } from '../../model/player.dto';
import { ClosableDialogTitle } from '../closable-dialog-title/closable-dialog-title.comp';
import { PlayerSelectionList } from './player-selection-list';
import { PlayerIcon } from '../player-icon/player-icon.comp';

export interface SelectPlayerProps {
    label: string;
    players: PlayerDto[];
    player: PlayerDto;
    open: boolean;
    onSelectPlayer: (playerId: number) => void;
    onCancel: () => void;
    onClick: () => void;
}

export const SelectPlayer = (props: SelectPlayerProps) => {
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedPlayersWithKeiner, setSortedPlayersWithKeiner] = useState(props.players);

    const { isMobile } = useScreenSize();

    useEffect(() => {
        const sortedPlayers = props.players.sort((a, b) => -b.name.localeCompare(a.name));
        const playerKeiner = new PlayerDto(undefined, 'Keiner', null, true, '');
        setSortedPlayersWithKeiner(
            [playerKeiner, ...sortedPlayers].filter(
                (player: PlayerDto) =>
                    searchTerm === '' ||
                    player.name.toUpperCase().startsWith(searchTerm.toUpperCase()) ||
                    player.name.toUpperCase().includes(searchTerm.toUpperCase())
            )
        );
    }, [props.players, searchTerm]);

    const selectPlayer = (playerId: number) => {
        props.onSelectPlayer(playerId);
        setSearchTerm('');
        setSuggestionIndex(0);
    };

    const menuItems = (
        <PlayerSelectionList
            players={sortedPlayersWithKeiner}
            onSelectPlayer={selectPlayer}
            suggestionIndex={suggestionIndex}
        />
    );

    const renderButtonContent = () => {
        if (!props.player) {
            return props.label + ' auswählen';
        }
        if (!props.player.realImageUrl) {
            return props.player.name;
        }
        return (
            <>
                <div style={{ height: 24, marginRight: 10 }}>
                    <PlayerIcon player={props.player} />
                </div>
                {props.player.name}
            </>
        );
    };

    const handleKeyUp = (event: { nativeEvent: { key: string } }) => {
        if (event.nativeEvent.key === 'ArrowDown' && suggestionIndex <= sortedPlayersWithKeiner.length - 1) {
            setSuggestionIndex(suggestionIndex + 1);
        }
        if (event.nativeEvent.key === 'ArrowUp' && suggestionIndex > 0) {
            setSuggestionIndex(suggestionIndex - 1);
        }
        if (event.nativeEvent.key === 'Enter' && sortedPlayersWithKeiner[suggestionIndex]) {
            selectPlayer(sortedPlayersWithKeiner[suggestionIndex].id);
        }
    };

    const inputField = (
        <TextField
            variant="standard"
            fullWidth={true}
            autoFocus={true}
            label={'Spielername'}
            value={searchTerm}
            style={{ marginBottom: 10 }}
            onChange={(event) => {
                setSearchTerm(event.target.value);
                setSuggestionIndex(0);
            }}
            onKeyUp={handleKeyUp}
        />
    );

    return (
        <>
            <Button
                variant="outlined"
                style={{ width: '50%', margin: 10 }}
                onClick={props.onClick}
            >
                {renderButtonContent()}
            </Button>
            <Dialog
                open={props.open}
                fullScreen={isMobile}
                onClose={props.onCancel}
            >
                <ClosableDialogTitle
                    title={`${props.label} auswählen`}
                    onClose={props.onCancel}
                />
                <DialogContent>
                    {inputField}
                    {menuItems}
                </DialogContent>
                <DialogActions />
            </Dialog>
        </>
    );
};
