import { Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Skeleton } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import moment from 'moment';
import React from 'react';
import { Game } from '../../model/game/game';
import { PlayerDto } from '../../model/player.dto';
import { GameSelectorTeam } from './game-selector-team.comp';
import './game-selector.css';

export interface GameSelectorProps {
    currentGame: Game;
    players: PlayerDto[];
    currentGameIndexFromEnd: number;
    gameRankingsLength: number;
    onSetCurrentGameIndexFromEnd: (currentGameIndexFromEnd: number) => void;
}

export const GameSelector = (props: GameSelectorProps) => {
    const isLoading = !props.currentGame || !props.players;

    const teamAWon = props.currentGame?.goalsTeamA > props.currentGame?.goalsTeamB;

    const theme = useTheme();

    return (
        <div
            className="game-selector"
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
        >
            <IconButton
                disabled={props.currentGameIndexFromEnd === -props.gameRankingsLength}
                title="Vorheriges Spiel"
                onClick={() => props.onSetCurrentGameIndexFromEnd(props.currentGameIndexFromEnd - 1)}
                size="large"
            >
                <ArrowLeft
                    className={`game-selector-icon ${
                        isLoading || props.currentGameIndexFromEnd === -props.gameRankingsLength ? 'transparent' : ''
                    }`}
                />
            </IconButton>
            <div style={{ flex: 1 }}>
                <Typography variant="caption">
                    {isLoading ? (
                        <Skeleton
                            variant="text"
                            height={24}
                            width={120}
                            style={{ margin: 'auto' }}
                        />
                    ) : (
                        `${moment(props.currentGame.date).format('DD.MM.YYYY, HH:mm')} Uhr`
                    )}
                </Typography>
                <div className="game-selector-teams-and-score">
                    <div className="game-selector-team-a">
                        <GameSelectorTeam
                            team={props.currentGame?.teamA}
                            bold={teamAWon}
                            players={props.players}
                        />
                    </div>
                    <Typography variant="body1">
                        <span className={teamAWon ? 'bold' : ''}>{!isLoading && props.currentGame.goalsTeamA}</span>
                        &nbsp;:&nbsp;
                        <span className={!teamAWon ? 'bold' : ''}>{!isLoading && props.currentGame.goalsTeamB}</span>
                    </Typography>
                    <div className="game-selector-team-b">
                        <GameSelectorTeam
                            team={props.currentGame?.teamB}
                            bold={!teamAWon}
                            players={props.players}
                        />
                    </div>
                </div>
                <Typography variant="caption">
                    {isLoading ? (
                        <Skeleton
                            variant="text"
                            width={100}
                            height={24}
                            style={{ margin: 'auto' }}
                        />
                    ) : (
                        `GameId: ${props.currentGame.id}`
                    )}
                </Typography>
            </div>
            <IconButton
                disabled={props.currentGameIndexFromEnd === -1}
                title="Nächstes Spiel"
                onClick={() => props.onSetCurrentGameIndexFromEnd(props.currentGameIndexFromEnd + 1)}
                size="large"
            >
                <ArrowRight
                    className={`game-selector-icon ${
                        isLoading || props.currentGameIndexFromEnd === -1 ? 'transparent' : ''
                    }`}
                />
            </IconButton>
        </div>
    );
};
