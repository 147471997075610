import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { GameDto } from '../model/game.dto';
import { useGameClient } from '../clients/game-client/game-client';

const pageSize = 20;

export interface AllGames {
    allGames: GameDto[];
    deleteGame: (id: number) => Promise<void>;
    loadingGames: boolean;
    errorLoadingGames: any;
    reload: () => void;
    loadNextPage: () => void;
    reloadCurrentPage: () => void;
    changedGames: GameDto[];
    addLocalGame: (game: GameDto) => void;
}

export const useGames: () => AllGames = () => {
    const [loadedGames, setLoadedGames] = useState<GameDto[]>();
    const [allGames, setAllGames] = useState<GameDto[]>();
    const [errorLoadingGames, setErrorLoadingGames] = useState(undefined);
    const [page, setPage] = useState(0);
    const [loadingGames, setLoadingGames] = useState(false);
    const [locallyAddedGames, setLocallyAddedGames] = useState<GameDto[]>([]);
    const [locallyDeletedGames, setLocallyDeletedGames] = useState<GameDto[]>([]);

    const { loadGames, removeGame } = useGameClient();

    const loadPage = useCallback(
        (page: number) => {
            setLoadingGames(true);
            loadGames({ page, size: pageSize })
                .then((response) => {
                    setLoadedGames(response);
                    setErrorLoadingGames(undefined);
                })
                .catch((err) => setErrorLoadingGames(err))
                .finally(() => setLoadingGames(false));
        },
        [loadGames]
    );

    const loadNextPage = useCallback(() => {
        setPage(page + 1);
        loadPage(page + 1);
    }, [loadPage, page]);

    const reloadCurrentPage = useCallback(() => loadPage(page), [loadPage, page]);

    const loadFirstPage = useCallback(() => loadPage(0), [loadPage]);

    useEffect(loadFirstPage, [loadFirstPage]);

    useEffect(() => {
        const existingIds = new Set<number>();
        const result: GameDto[] = [];

        addGamesIfNotPresent(result, allGames, existingIds);
        addGamesIfNotPresent(result, loadedGames, existingIds);
        addGamesIfNotPresent(result, locallyAddedGames, existingIds);

        setAllGames(result.sort((gameA, gameB) => moment(gameB.date).diff(gameA.date)));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locallyAddedGames, loadedGames]);

    const deleteGame = (id: number) =>
        removeGame(id).then(() => {
            setLocallyDeletedGames([...locallyDeletedGames, allGames.find((game) => game.id === id)]);

            setLoadedGames([...loadedGames].filter((game) => game.id !== id));
            setAllGames([...allGames].filter((game) => game.id !== id));
            setLocallyAddedGames([...locallyAddedGames].filter((game) => game.id !== id));
        });

    const addLocalGame = (game: GameDto) => {
        setLocallyAddedGames([...locallyAddedGames, game]);
    };

    return {
        allGames,
        errorLoadingGames,
        reload: loadFirstPage,
        loadNextPage,
        loadingGames,
        reloadCurrentPage,
        deleteGame,
        changedGames: [...locallyAddedGames, ...locallyDeletedGames],
        addLocalGame,
    };
};

function addGamesIfNotPresent(result: GameDto[], games: GameDto[], existingIds: Set<number>) {
    if (games) {
        result.push(...games.filter((game) => !existingIds.has(game.id)));
        games.forEach((game) => existingIds.add(game.id));
    }
}
