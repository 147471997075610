import Button from '@mui/material/Button';
import { ButtonGroup, useTheme } from '@mui/material';
import * as React from 'react';
import { CSSProperties } from 'react';

export interface ResultButtonsProps {
    selectedValue: number | undefined;
    onSelectValue: (value: number) => void;
}

export const ResultButtons = (props: ResultButtonsProps) => {
    const theme = useTheme();

    const renderButtons = () => {
        let buttons: JSX.Element[] = [];
        for (let score = 0; score <= 6; score++) {
            const color: CSSProperties =
                props.selectedValue === score
                    ? {
                          backgroundColor: theme.palette.primary.light,
                          color: theme.palette.secondary.contrastText,
                      }
                    : {
                          backgroundColor: theme.palette.grey.A100,
                          color: theme.palette.text.primary,
                      };
            buttons.push(
                <Button
                    key={score}
                    style={{
                        ...color,
                        padding: 10,
                        minWidth: 20,
                        width: '15%',
                    }}
                    data-testid={`score_button_${score}`}
                    onClick={() => props.onSelectValue(score)}
                >
                    {score}
                </Button>
            );
        }
        return buttons;
    };

    return (
        <ButtonGroup
            variant="contained"
            style={{ marginBottom: 10, marginTop: 10, width: '100%' }}
        >
            {renderButtons()}
        </ButtonGroup>
    );
};
