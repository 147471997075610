import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import React from 'react';

interface Tab {
    name: string;
    icon: JSX.Element;
}

export interface FooterTabsProps {
    currentTab: number;
    onTabChange: (newIndex: number) => void;
    tabs: Tab[];
}

export const FooterTabs = (props: FooterTabsProps) => (
    <>
        <div style={{ height: 56 }}></div>
        <BottomNavigation
            value={props.currentTab}
            onChange={(_event, value) => props.onTabChange(value)}
            style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.3)',
            }}
            showLabels
        >
            {props.tabs.map((tab, index) => (
                <BottomNavigationAction
                    key={index}
                    label={tab.name}
                    icon={tab.icon}
                />
            ))}
        </BottomNavigation>
    </>
);
