import { useCallback, useContext } from 'react';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { NameAlreadyInUseError } from './http-client.errors';

export const useHttpClient = () => {
    const { token } = useContext<IAuthContext>(AuthContext);
    const get = useCallback(
        async <T>(url: string): Promise<T> => {
            const response = await fetch(url, {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.ok) {
                return response.json();
            }
            throw response;
        },
        [token]
    );
    const getRaw = useCallback(
        async (url: string): Promise<Response> => {
            const response = await fetch(url, {
                method: 'GET',
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.ok) {
                return response;
            }
            throw response;
        },
        [token]
    );
    const post = useCallback(
        async <T, R>(url: string, data: T): Promise<R> => {
            const response = await fetch(url, {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                return response.json();
            }
            if (response.status === 409) {
                throw new NameAlreadyInUseError();
            }
            throw response;
        },
        [token]
    );
    const patch = useCallback(
        async <T, R>(url: string, data: T): Promise<R> => {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                return response.json();
            }
            if (response.status === 409) {
                throw new NameAlreadyInUseError();
            }
            throw response;
        },
        [token]
    );

    const remove = useCallback(
        async (url: string): Promise<void> => {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.ok) {
                return null;
            }
            throw new Error(`Delete not successful, http status was ${response.status}`);
        },
        [token]
    );
    return { get, getRaw, post, patch, remove };
};
