import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

export interface AsyncButtonProps {
    loading: boolean;
    disabled: boolean;
    onClick: () => void;
    text?: string;
    icon?: JSX.Element;
    testid?: string;
}

const divWithWidth = (width: number) => <div style={{ width: width }}></div>;

const getCircularProgress = (props: AsyncButtonProps) => {
    if (!props.loading) {
        return divWithWidth(10);
    }
    return (
        <CircularProgress
            size={24}
            data-testid="circularProgress"
        />
    );
};

function getEndIcon(loading: boolean) {
    if (loading) {
        return undefined;
    }
    return divWithWidth(10);
}

export const AsyncButton = (props: AsyncButtonProps) => (
    <div>
        <Button
            color="primary"
            variant="contained"
            data-testid={props.testid}
            disabled={props.disabled}
            onClick={props.onClick}
            startIcon={getCircularProgress(props)}
            endIcon={getEndIcon(props.loading)}
        >
            {props.icon}
            {props.text}
        </Button>
    </div>
);
