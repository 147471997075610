import { useEffect, useState } from 'react';
import { RankingTypeDto } from '../model/ranking/ranking-type.dto';
import { useRankingClient } from '../clients/ranking-client/ranking-client';

export const useRankingTypes = () => {
    const [rankingTypes, setRankingTypes] = useState<RankingTypeDto[]>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const { loadRankingTypes } = useRankingClient();

    const load = () => {
        setLoading(true);
        loadRankingTypes()
            .then((response) => {
                setError(undefined);
                setRankingTypes(response);
            })
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    };

    useEffect(load, [loadRankingTypes]);

    return { rankingTypes, isLoading, error, reload: load };
};
