import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { GameDto } from '../../model/game.dto';
import { PlayerDto } from '../../model/player.dto';
import { TeamDto } from '../../model/team.dto';
import './games-table-row.css';
import { IconSkeleton, PlayerIcon } from '../player-icon/player-icon.comp';

export const GamesTableRow = (props: {
    game?: GameDto;
    players?: PlayerDto[];
    showStandortCol: boolean;
    showIconCols: boolean;
    nameColMaxWidth: number;
    onDeleteGame(id: number): void;
}) => {
    const getPlayerForId = (id: number) => props.players.find((player) => player.id === id);
    return (
        <TableRow
            style={getRowStyle(props.game)}
            className={!props.game ? 'loading-game-row' : ''}
            onClick={() => props.game && props.onDeleteGame(props.game.id)}
        >
            <TableCell>
                {props.game ? (
                    <>
                        {moment(props.game.date).format('DD.MM.YY')} <br />
                        {moment(props.game.date).format('HH:mm')}
                    </>
                ) : (
                    <>
                        <Skeleton
                            variant="text"
                            data-testid="skeleton"
                        />
                        <Skeleton variant="text" />
                    </>
                )}
            </TableCell>
            {props.showStandortCol && (
                <TableCell align="center">{props.game ? props.game.standort : <Skeleton variant="text" />}</TableCell>
            )}
            {props.showIconCols && (
                <TableCell className="padding-4">
                    {props.game ? (
                        <TeamIcons
                            team={props.game.teamA}
                            players={props.players}
                        />
                    ) : (
                        <>
                            <IconSkeleton />
                            <IconSkeleton />
                        </>
                    )}
                </TableCell>
            )}
            <TableCell
                className="text-ellipsis"
                style={{ maxWidth: props.nameColMaxWidth, lineHeight: '36px' }}
            >
                {props.game ? (
                    <>
                        {renderPlayerName(getPlayerForId(props.game.teamA.idPlayerA))} <br />
                        {renderPlayerName(getPlayerForId(props.game.teamA.idPlayerB))}
                    </>
                ) : (
                    <>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </>
                )}
            </TableCell>
            <TableCell className="padding-4">
                {props.game ? props.game.goalsTeamA : <Skeleton variant="text" />}
            </TableCell>
            <TableCell
                align="right"
                className="padding-4"
            >
                {props.game ? props.game.goalsTeamB : <Skeleton variant="text" />}
            </TableCell>
            <TableCell
                align="right"
                className="text-ellipsis"
                style={{ maxWidth: props.nameColMaxWidth, lineHeight: '36px' }}
            >
                {props.game ? (
                    <>
                        {renderPlayerName(getPlayerForId(props.game.teamB.idPlayerA))} <br />
                        {renderPlayerName(getPlayerForId(props.game.teamB.idPlayerB))}
                    </>
                ) : (
                    <>
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </>
                )}
            </TableCell>
            {props.showIconCols && (
                <TableCell
                    className="padding-4"
                    style={{ paddingRight: 16 }}
                >
                    {props.game ? (
                        <TeamIcons
                            team={props.game.teamB}
                            players={props.players}
                        />
                    ) : (
                        <>
                            <IconSkeleton />
                            <IconSkeleton />
                        </>
                    )}
                </TableCell>
            )}
        </TableRow>
    );
};

const getRowStyle = (game: GameDto) => {
    if (game?.lastGame) {
        return { backgroundColor: '#d8f4d8' } as CSSProperties;
    }
};

const TeamIcons = (props: { team: TeamDto; players: PlayerDto[] }) => {
    const playerA = getPlayerForId(props.team.idPlayerA, props.players);
    const playerB = props.team.idPlayerB && getPlayerForId(props.team.idPlayerB, props.players);

    return (
        <div className="games-table-icon-container">
            <PlayerIcon player={playerA} />
            {playerB && <PlayerIcon player={playerB} />}
        </div>
    );
};

const renderPlayerName = (player: PlayerDto) => {
    if (player) {
        return player.name;
    }
};

const getPlayerForId = (id: number, players: PlayerDto[]) => players.find((player) => player.id === id);
